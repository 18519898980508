// @flow
import type {Selector} from 'common/redux/types';
import {selectCategories, selectGeolocation} from 'data/app/selectors';
import type {AutocompleteItem} from 'data/search/graphql/queries/autocomplete';
import type {SearchQueryVariables} from 'data/search/graphql/queries/search';
import type {SearchState} from 'data/search/reducer';
import type {SearchParams} from 'data/search/types';
import type {DateRangeValue} from 'data/units/date/types';
import {compose, prop, values} from 'ramda';

import {getEnumFilters, getManufacturerIds, getProductIds, getRangeFilters} from './helpers';

const base: Selector<SearchState> = prop('search');

export const selectSearchParams: Selector<SearchParams> = compose(prop('params'), base);

export const selectDates: Selector<?DateRangeValue> = compose(prop('dates'), base);

export const selectRecentSearches: Selector<AutocompleteItem[]> = compose(
  prop('recentSearches'),
  base
);

export const selectIsSearchEmpty: Selector<boolean> = state => {
  // TODO fix once complete
  const params = selectSearchParams(state);
  const dates = selectDates(state);

  return !dates && values(params).every(x => !x);
};

export const selectSearchQueryVariables: Selector<SearchQueryVariables> = state => {
  const params = selectSearchParams(state);
  const categories = selectCategories(state);
  const geolocations = selectGeolocation(state);
  const dates = selectDates(state);

  return {
    categories: categories && categories.map(category => category.name),
    locationIds: geolocations && geolocations.map(geo => geo.id),
    name: params.name,
    productIds: getProductIds(params),
    start: dates && dates.startDate,
    end: dates && dates.endDate,
    manufacturerIds: getManufacturerIds(params),
    rangeFilters: getRangeFilters(params),
    enumFilters: getEnumFilters(params),
    discountId: params.discountId,
  };
};
