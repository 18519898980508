// @flow
import type {ID} from 'data/enums/types';

const urls = {
  // Placeholder link due to the real link no longer existing but where we want
  // to keep the unused component mostly intact.
  // See: https://app.clubhouse.io/awayco/story/1238/user-app-ui-changes
  placeholder: '/',

  // non-auth
  home: '/',
  explore: '/explore',
  terms: '/terms',
  privacy: 'https://www.awayco.com/privacy',
  registerYourStore: '/register-your-store',
  welcomeToAwayco: '/welcome-to-awayco',
  awayco: 'https://www.awayco.com',
  debug: '/debug',
  shoppingBasket: '/cart',
  masqueradeClaim: (claimToken: ID) => `/masquerade/claim/${claimToken}`,
  rentalAgreement: '/rental-agreement',

  // auth only
  reservations: '/reservations',
  reservation: (id: ID) => `/reservations/${id}`,
  extendReservation: (productVariantId: ID, affiliateId: ID) =>
    `/extend-reservation/${productVariantId}/${affiliateId}/checkout`,
  deliveryAddress: '/delivery',
  basketCheckout: '/checkout',

  account: {
    // Placeholder link due to the real link no longer existing but where we want
    // to keep the unused component mostly intact
    // See: https://app.clubhouse.io/awayco/story/1238/user-app-ui-changes
    placeholder: '/account',

    index: '/account',
    password: '/account/password',
    phoneNumber: '/account/phone-number',
    payments: '/account/payments',
    deleteAccount: '/account/delete-account',
  },

  search: '/search',
  products: '/products',
  product: (productId: ID, affiliateId: ID) => `/products/${productId}/${affiliateId}`,
  bundle: (bundleId: ID, affiliateId: ID) => `/bundle/${bundleId}/${affiliateId}`,
  cart: (productVariantId: ID, affiliateId: ID) =>
    `/products/${productVariantId}/${affiliateId}/cart`,
  affiliate: (id: ID) => `/affiliate/${id}`,
  brand: (name: string) => `/brand/${name}`,
  resetPassword: '/reset-password',

  //***Deprecated routes***
  //Required to redirect to their new versions
  reservationDeprecated: (id: ID) => `/reservation/${id}`,
  productDeprecated: (productId: ID, affiliateId: ID) => `/product/${productId}/${affiliateId}`,
  cartDeprecated: (productVariantId: ID, affiliateId: ID) =>
    `/product/${productVariantId}/${affiliateId}/cart`,
  shoppingBasketDeprecated: '/bag',
  basketCheckoutDeprecated: '/bag-checkout',

  returnConfirmation: '/return-confirmation',
};
export default urls;
