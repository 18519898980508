// @flow
import styled, {css} from 'styled-components';

export const FilterInputWrap = styled.div`
  font-size: 1.4rem;
  font-weight: ${props => props.theme.fontWeights.book};
  line-height: 3.2rem;
  padding: 0 1.2rem;
  white-space: nowrap;
  margin-right: 0.6rem;
  border-radius: ${props => props.theme.borderRadiuses.filterButton};
  color: ${props => props.theme.color.primaryText};
  background: ${props => props.theme.color.light};
  cursor: pointer;

  ${p =>
    !p.empty &&
    css`
      background: ${props => props.theme.color.primaryAccent};
      color: ${props => props.theme.color.white};
    `}
`;
