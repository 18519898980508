// @flow
import {Text} from 'componentsStyled/Typography/Texts';
import {SubTitle} from 'componentsStyled/Typography/Titles';
import {selectAppConfig} from 'data/app/selectors';
import type {BundleOffering} from 'data/bundle/types';
import {minHeightImg, selectIndexImage} from 'data/images/helpers';
import urls from 'data/router/urls';
import {formatPricing} from 'data/units/money/formatters';
import withConnect from 'hoc/withConnect';
import withRouter from 'hoc/withRouter';
import {groupBy, keys} from 'ramda';
import React from 'react';
import {type HOC, compose, withHandlers} from 'recompose';

import {Body, ImageWrap, ProductTileWrap} from './styled';

const BundleTile = ({bundleOffering, maxTilesPerRow, handleClick, appConfig}) => {
  const showMinimalProductTileInfo = appConfig && appConfig.showMinimalProductTileInfo;
  const indexImage = selectIndexImage(bundleOffering.images);
  const imageSize = minHeightImg(indexImage, 250);
  const prices = evaluateBundlePrice(bundleOffering);
  const currency = showMinimalProductTileInfo ? undefined : bundleOffering.currency;
  return (
    <ProductTileWrap
      onClick={handleClick}
      minimal={showMinimalProductTileInfo}
      maxTilesPerRow={maxTilesPerRow}
    >
      <ImageWrap>
        <img src={imageSize.url} alt={indexImage.alt} draggable="false" />
      </ImageWrap>
      <Body>
        <SubTitle>{bundleOffering.name}</SubTitle>
        <Text small>
          <Text small inline black book>
            {formatPricing(prices, currency)}
          </Text>{' '}
          per day
        </Text>
        {!showMinimalProductTileInfo && <Text small>{bundleOffering.affiliateName}</Text>}
      </Body>
    </ProductTileWrap>
  );
};

const mapStateToProps = state => ({
  appConfig: selectAppConfig(state),
});

const evaluateBundlePrice = (bundleOffering: BundleOffering) => {
  let min = 0;
  let max = 0;

  const bundleComponentsSortedByProduct = groupBy(
    x => String(x.productVariant.product.id),
    bundleOffering.components
  );

  // Get max and min price for each product the bundle offers
  const productId = keys(bundleComponentsSortedByProduct);
  productId.forEach(productId => {
    const componentList = bundleComponentsSortedByProduct[productId];
    const pricingList = [];
    componentList.forEach(component => {
      pricingList.push(...component.pricingDetails.prices);
    });
    min += Math.min(...pricingList);
    max += Math.max(...pricingList);
  });

  if (max === min) {
    return [max];
  }
  return [max, min];
};

type Outter = {|
  bundleOffering: BundleOffering,
  maxTilesPerRow?: number,
|};

const enhancer: HOC<*, Outter> = compose(
  withRouter,
  withConnect(mapStateToProps),
  withHandlers({
    handleClick: props => () => {
      props.history.push(
        urls.bundle(props.bundleOffering.bundleId, props.bundleOffering.affiliateId)
      );
    },
  })
);

export default enhancer(BundleTile);
