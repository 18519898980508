// @flow strict-local
import type {ProductVariant} from 'data/product/types';
import React from 'react';
import {type HOC, compose, withProps, withStateHandlers} from 'recompose';

import VariableSelect from './VariableSelect';

/**
 * Takes an array of arrays that are of equal sizes, and returns another array of arrays.
 * The returned arrays each contain elements that had the same index positions with
 * respect to the original input.
 *
 * e.g.
 * input: [[a, b], [c, d], [e, f]]
 * output: [[a, c, e], [b, d, f]]
 */
const zip = arrays => {
  return arrays[0].map((_, i) => {
    return arrays.map(array => {
      return array[i];
    });
  });
};

const removeDuplicates = list => {
  return list.filter((value, index) => list.indexOf(value) === index);
};

const VariantVariablesSelect = ({
  variantVariablesLabels,
  updateSelectedVariantVariables,
  productVariants,
}) => {
  const variantVariableOptionsList = [];
  const untokenisedVariantVariableOptionsList = productVariants.map(
    productVariant => productVariant.name
  );

  const tokenizedVariantVariablesOptionsList = untokenisedVariantVariableOptionsList.map(name => {
    const tokenizedString = name.split(' | ');
    return tokenizedString.map(name => name.trim());
  });

  // All variant variables with the same label are put togther, i.e., all 'Colour' variable options are put together
  variantVariableOptionsList.push(
    ...zip(tokenizedVariantVariablesOptionsList).map(variantVariableOptions => {
      return removeDuplicates(variantVariableOptions);
    })
  );

  return (
    <React.Fragment>
      {variantVariablesLabels.map((label, index) => {
        return (
          <VariableSelect
            updateSelectedVariantVariables={updateSelectedVariantVariables}
            label={label}
            variantVariableOptions={variantVariableOptionsList[index]}
          />
        );
      })}
    </React.Fragment>
  );
};

type Outter = {
  variantVariables: string,
  productVariants: ProductVariant[],
  onSelectVariant: (?number) => void,
};

const enhancer: HOC<*, Outter> = compose(
  withProps(props => {
    const variantVariablesLabels = props.variantVariables.split(' | ').map(label => label.trim());

    return {
      variantVariablesLabels: variantVariablesLabels,
    };
  }),
  withStateHandlers(
    props => {
      const initialVariantVariables = props.productVariants[0].name
        .split(' | ')
        .map(variantVariableLabel => variantVariableLabel.trim());

      // create selectedVariantVariables, this will maintain the current selection of variables
      const selectedVariantVariables = {};
      props.variantVariablesLabels.forEach(
        (key, i) => (selectedVariantVariables[key] = initialVariantVariables[i])
      );

      return {
        selectedVariantVariables: selectedVariantVariables,
      };
    },
    {
      // State handler called by VariableSelect
      updateSelectedVariantVariables: (state, props) => (label, variantVariableOption) => {
        const temp = state.selectedVariantVariables;
        temp[label] = variantVariableOption;

        const variantVariablesOptions = Object.values(temp).join(' | ');

        // Recall that the variant variables are combined to produce the product variant name
        const selectedVariant = props.productVariants.find(productVariant => {
          return productVariant.name === variantVariablesOptions;
        });

        // if variant id isn't found, return null
        props.onSelectVariant(selectedVariant ? selectedVariant.id : selectedVariant);

        return {
          selectedVariantVariables: temp,
        };
      },
    }
  )
);
export default enhancer(VariantVariablesSelect);
