// @flow
import ArrowIcon from 'assets/icons/ArrowIcon';
import {Flex} from 'componentsStyled/Layout/Flex';
import {Text} from 'componentsStyled/Typography/Texts';
import type {EnumValue} from 'data/enums/types';
import * as React from 'react';

import {EnumFilterWrap, TextWrapper} from './styled';

type Props = {|
  title: string,
  value: ?(EnumValue[]),
  onClick: Function,
|};

const EnumFilter = ({title, value, onClick}: Props) => (
  <EnumFilterWrap onClick={onClick} stack>
    <Text black>{title}</Text>
    <Flex>
      <TextWrapper>
        {value && value.length > 0 ? value.map(x => x.name).join(', ') : 'Any'}
      </TextWrapper>
      <ArrowIcon rotation="right" marginLeft />
    </Flex>
  </EnumFilterWrap>
);

export default EnumFilter;
