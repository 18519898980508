// @flow
import Desktop from 'components/Media/Desktop';
import Mobile from 'components/Media/Mobile';
import RelatedProducts from 'components/RelatedProducts';
import {Container} from 'componentsStyled/Layout/Containers';
import {Flex} from 'componentsStyled/Layout/Flex';
import {Space} from 'componentsStyled/Layout/Spacers';
import {Title} from 'componentsStyled/Typography/Titles';
import {selectAppConfig} from 'data/app/selectors';
// import { listProductVariantsQuery } from "data/product/graphql/queries"
import {listAffiliateBundleComponentsQuery} from 'data/bundle/queries';
import type {ID} from 'data/enums/types';
import urls from 'data/router/urls';
import withConnect from 'hoc/withConnect';
import withQuery from 'hoc/withQuery';
import withUser from 'hoc/withUser';
import SubPage from 'pages/_Page/SubPage';
import {DetailWrap, DetailWrapNoPadding} from 'pages/Product/Detail/styled';
import Gallery from 'pages/Product/Gallery';
import {Half} from 'pages/Product/styled';
import {clone, groupBy, keys} from 'ramda';
import React from 'react';
import {type HOC, compose, withProps, withStateHandlers} from 'recompose';

import BundleDetail from './BundleDetail';
import BundleSelect from './BundleSelect';

// TODO(Jude): Possibly generalise `Product` page with `Bundle` page
const Bundle = ({
  data,
  selectedBundleComponents,
  bundleComponentsSortedByProduct,
  onSelectBundleComponent,
  appConfig,
  activity,
  geolocations,
  categories,
  params,
  ...props
}) => {
  if (data.bundleComponents.length === 0) {
    return null;
  }
  const representativeBundle = data.bundleComponents[0].bundle;
  const relatedProductsComponent = (
    <RelatedProducts
      categoryName={representativeBundle.category.name}
      // HACK/TODO(Jude): Remove and fix error that results when productId is undefined (which It should be in this case)
      productId={-1}
      isInsideBasket={false}
    />
  );

  const contentComponent = (
    <React.Fragment>
      <DetailWrap>
        <Title>{representativeBundle.name}</Title>
        <React.Fragment>
          <BundleSelect
            bundleComponentsSortedByProduct={bundleComponentsSortedByProduct}
            selectedBundleComponents={selectedBundleComponents}
            onSelectBundleComponent={onSelectBundleComponent}
          />
          <Space />
          <BundleDetail
            selectedBundleComponents={selectedBundleComponents}
            bundleDescription={representativeBundle.description}
            affiliate={data.affiliate}
          />
          <Space />
        </React.Fragment>
      </DetailWrap>
    </React.Fragment>
  );

  return (
    <SubPage title={representativeBundle.name} backUrl={urls.products}>
      <Gallery images={representativeBundle.images} />
      <Mobile>
        {contentComponent}
        <DetailWrapNoPadding>{relatedProductsComponent}</DetailWrapNoPadding>
      </Mobile>
      <Desktop>
        <Container>
          <Flex>
            <Half />
            <Half white>{contentComponent}</Half>
          </Flex>
          <Space />
          {relatedProductsComponent}
        </Container>
      </Desktop>
    </SubPage>
  );
};

type Outter = {|
  match: {|
    params: {|
      bundleId: ID,
      affiliateId: ID,
    |},
  |},
|};

const mapStateToProps = state => ({
  appConfig: selectAppConfig(state),
});

const enhancer: HOC<*, Outter> = compose(
  withUser(),
  withConnect(mapStateToProps),
  withQuery(listAffiliateBundleComponentsQuery, {
    variables: props => ({
      bundleId: props.match.params.bundleId,
      affiliateId: props.match.params.affiliateId,
    }),
  }),
  withProps(props => {
    // Sort bundle components by product and use productId as key to identify bundle
    // components that refer to the same product
    const bundleComponentsSortedByProduct = groupBy(
      x => String(x.productVariant.product.id),
      props.data.bundleComponents
    );
    return {
      bundleComponentsSortedByProduct: bundleComponentsSortedByProduct,
    };
  }),
  withStateHandlers(
    props => {
      // Initial selected bundle components to be the first element in sorted bundle-product object
      const selectedBundleComponents = {};
      keys(props.bundleComponentsSortedByProduct).forEach((key, index) => {
        selectedBundleComponents[key] = props.bundleComponentsSortedByProduct[key][0];
      });

      return {
        selectedBundleComponents: selectedBundleComponents,
      };
    },
    {
      onSelectBundleComponent: (state, props) => (productId, componentId) => {
        // TODO(Jude): Understand what's happening with tracking
        // if (variant) {
        //   trackProductView(variant, props.user)
        // }
        const selectedBundleComponents = clone(state.selectedBundleComponents);
        const newlySelectedcomponent = props.data.bundleComponents.find(
          component => component.id === componentId
        );
        selectedBundleComponents[productId] = newlySelectedcomponent;
        return {
          selectedBundleComponents,
        };
      },
    }
  )
);

export default enhancer(Bundle);
