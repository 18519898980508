// @flow
import {chooseCategories, chooseGeolocation} from 'data/app/actions';
import {formatCategoryFilter, formatLocation} from 'data/app/formatters';
import type {Category, Location} from 'data/app/types';
import {formatEnumArray} from 'data/enums/formatters';
import {clear, filter} from 'data/search/actions';
import {type SearchParams} from 'data/search/types';
import withRouter from 'hoc/withRouter';
import {isEmpty, isNil, omit, values} from 'ramda';
import React from 'react';
import {type HOC} from 'recompose';
import BrandFilter from 'searchFilters/FilterComponents/BrandFilter';
import CategoryFilter from 'searchFilters/FilterComponents/CategoryFilter';
import LocationFilter from 'searchFilters/FilterComponents/LocationFilter';
import ProductFilter from 'searchFilters/FilterComponents/ProductFilter';
import DesktopFilter from 'searchFilters/Shared/DesktopFilter';

import {ClearButton} from './styled';

type Props = {|
  geolocation: ?(Location[]),
  categories: ?(Category[]),
  clear: typeof clear,
  chooseGeolocation?: typeof chooseGeolocation,
  chooseCategories: typeof chooseCategories,
  params: SearchParams,
  filter: typeof filter,
  multipleBrands: boolean,
|};

const DesktopFilters = ({
  chooseGeolocation,
  geolocation,
  categories,
  chooseCategories,
  filter,
  clear,
  params,
  location,
  multipleBrands = true,
}) => {
  // Affiliate filter value is context from the affiliate page so it's not clearable
  const filterParams = omit(['affiliate'], params);
  const filterEmpty =
    isNil(filterParams) ||
    isEmpty(filterParams) ||
    values(filterParams).every(isNil) ||
    values(filterParams).every(isEmpty);

  return (
    <React.Fragment>
      <DesktopFilter
        value={categories}
        onChange={chooseCategories}
        component={CategoryFilter}
        formatter={formatCategoryFilter('Category')}
        title="Category"
      />
      {chooseGeolocation && (
        <DesktopFilter
          value={geolocation}
          onChange={chooseGeolocation}
          component={LocationFilter}
          formatter={formatLocation}
          title="Location"
          minWidth={28}
          closeOnChange
        />
      )}
      {multipleBrands && (
        <DesktopFilter
          value={params.brand}
          onChange={brand => filter({brand})}
          formatter={formatEnumArray('Brand')}
          component={BrandFilter}
          title="Brand"
          noXPadding
        />
      )}
      <DesktopFilter
        value={params.product}
        onChange={product => filter({product})}
        formatter={formatEnumArray('Product')}
        component={ProductFilter}
        title="Product"
        noXPadding
      />
      {!filterEmpty && (
        <ClearButton primary onClick={clear}>
          Clear
        </ClearButton>
      )}
    </React.Fragment>
  );
};

const enhancer: HOC<*, Props> = withRouter;

export default enhancer(DesktopFilters);
