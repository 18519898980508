// @flow
import type {AffiliateDetailQueryVariables} from 'data/affiliate/graphql';
import {affiliateDetailQuery} from 'data/affiliate/graphql';
import {chooseActivity, chooseGeolocation} from 'data/app/actions';
import {selectActivity, selectGeolocation} from 'data/app/selectors';
import urls from 'data/router/urls';
import {clear, clearAffiliate, filter} from 'data/search/actions';
import {
  selectIsSearchEmpty,
  selectSearchParams,
  selectSearchQueryVariables,
} from 'data/search/selectors';
import type {SearchParams} from 'data/search/types';
import withConnect from 'hoc/withConnect';
import withOnMount from 'hoc/withOnMount';
import withOnUnmount from 'hoc/withOnUnmount';
import withQuery from 'hoc/withQuery';
import withScrollEnd from 'hoc/withScrollEnd';
import AffiliateFilter from 'pages/_Page/MainFilter/AffiliateFilter';
import SubPage from 'pages/_Page/SubPage';
import {isEmpty} from 'ramda';
import React from 'react';
import {type HOC, compose} from 'recompose';

import Content from './Content';

const AffiliatePage = ({data, loadingMore, ...props}) => (
  <SubPage title={data.affiliate.name} backUrl={urls.products} filter={AffiliateFilter}>
    <Content {...data} loadingMore={loadingMore} />
  </SubPage>
);

const mapStateToProps = state => ({
  activity: selectActivity(state),
  geolocation: selectGeolocation(state),
  searchEmpty: selectIsSearchEmpty(state),
  searchParams: selectSearchParams(state),
  queryVariables: selectSearchQueryVariables(state),
});

const mapDispatchToProps = {
  chooseActivity,
  chooseGeolocation,
  clear,
  clearAffiliate,
  filter,
};

type Outter = {|
  searchParams: SearchParams,
  queryVariables: AffiliateDetailQueryVariables,
|};

const enhancer: HOC<*, Outter> = compose(
  withConnect(mapStateToProps, mapDispatchToProps),
  withQuery(affiliateDetailQuery, {
    variables: props => ({
      ...props.queryVariables,
      locationIds: [],
    }),
  }),
  withOnMount(props => {
    const {searchParams, data, filter} = props;
    const {affiliate, manufacturers = []} = data;

    const affiliateFilter = {};
    const brands = searchParams.brand || [];
    if (brands.length && manufacturers.length) {
      affiliateFilter.brand = brands.filter(b => manufacturers.find(m => m.id === b.id));
    }
    if (searchParams.affiliate !== affiliate.id) {
      affiliateFilter.affiliate = affiliate.id;
    }

    if (!isEmpty(affiliateFilter)) {
      filter(affiliateFilter);
    }
  }),
  withOnUnmount(props => {
    props.clearAffiliate();
  }),
  withScrollEnd(props => props.loadMore())
);

export default enhancer(AffiliatePage);
