// @flow
import {noop} from 'common/functions';
import {chooseActivity, chooseCategories, chooseGeolocation} from 'data/app/actions';
import {URLParam} from 'data/app/constants';
import {
  categoryListQuery,
  geolocationListQuery,
  locationByNameQuery,
} from 'data/app/graphql/queries';
import {parseQueryString, transformActivitiesParam} from 'data/app/redirectHelpers';
import {manufacturerByNameQuery} from 'data/manufacturer/queries';
import {listProductsMinimalQuery} from 'data/product/graphql/queries';
import urls from 'data/router/urls';
import {clear, filter} from 'data/search/actions';
import {selectLoggedIn} from 'data/user/selectors';
import {query} from 'global/apolloClient/helpers';
import store from 'global/store';
import withConnect from 'hoc/withConnect';
import withRouter from 'hoc/withRouter';
import {prop} from 'ramda';
import React from 'react';
import {Redirect, Route} from 'react-router-dom';
import type {Component, HOC} from 'recompose';
import {compose} from 'recompose';

/**
 * Converts a standard url case insensitive dash-separated string into
 * a standard space separated name (e.g.: 'black-crows' to 'Black Crows')
 */
const convertsUrlStandardToRegularName = (rawFormat: string): string =>
  rawFormat
    .toLowerCase()
    .split('-')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

/**
 * Converts any name with special characters, to the above RegularName convention
 * (e.g.: 'blaCk'& Crow's' to 'Black Crows')
 * or a more likely example, (e.g.: 'Fishing-kit' to 'Fishing Kit', 'Boy's Shirts & Hoodies' to 'Boys Shirts Hoodies)
 */
const convertAnyNameToRegularName = (rawFormat: string): string =>
  rawFormat
    .toLowerCase()
    .replace(/-/g, ' ')
    .replace(/[^a-zA-Z0-9 ]/g, '')
    .split(/\s+/)
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

/**
 * Updates the global state from the url parameters & forwards users
 * to the requested route.
 * NB: permission to a given route is defined in the backend.
 */
const LoggedOutRoute = ({component, loggedIn, location, chooseActivity, history, ...rest}) => {
  try {
    const urlParams = location && parseQueryString(location.search);

    const includedParams = Boolean(urlParams && Object.keys(urlParams).length >= 1);

    if (includedParams) {
      clear();
    }

    //Apply currently selected activity filter to the global state.
    const categoryParam = prop(URLParam.activity, urlParams);
    if (categoryParam) {
      const activity = transformActivitiesParam(String(categoryParam.toLowerCase()));
      activity && chooseActivity(activity);
    }

    //Apply currently selected brand filter to the global state.
    const brandParam = prop(URLParam.brand, urlParams);
    if (brandParam) {
      const convertedBrandName = convertsUrlStandardToRegularName(String(brandParam));

      query(manufacturerByNameQuery, {name: convertedBrandName})
        .then(brand => store.dispatch(filter({brand: [brand]})))
        .catch(() => noop());
    }

    //Apply currently selected affiliate filter to the global state
    // & redirect users to the relevant route.
    const affiliateParam = prop(URLParam.affiliate, urlParams);
    if (affiliateParam) {
      const productParam = prop(URLParam.product, urlParams);
      if (productParam) {
        return <Redirect to={urls.product(String(productParam), String(affiliateParam))} />;
      }
      store.dispatch(filter({affiliate: parseInt(affiliateParam, 10)}));
      return <Redirect to={urls.affiliate(String(affiliateParam))} />;
    }

    //Apply currently selected location filter to the global state.
    const locationParam = prop(URLParam.location, urlParams);
    if (locationParam) {
      const convertedLocationParam = convertsUrlStandardToRegularName(String(locationParam));

      query(locationByNameQuery, {name: convertedLocationParam})
        .then(loc => store.dispatch(chooseGeolocation([loc])))
        .catch(() => noop());
    }

    // Apply product filter passed in from query parameter
    const productParam = prop(URLParam.product, urlParams);
    if (productParam) {
      const name = String(productParam);
      query(listProductsMinimalQuery, {filter: {name}})
        .then(products =>
          store.dispatch(filter({product: products.map(p => ({id: p.id, name: p.name}))}))
        )
        .catch(noop);
    }

    // If we are not searching and no filter is requested then select nearby by default
    if (
      location &&
      location.pathname === urls.products &&
      !categoryParam &&
      !brandParam &&
      !affiliateParam &&
      !locationParam
    ) {
      query(geolocationListQuery)
        .then(locations => {
          const nearby = locations.filter(location => location.nearby);
          if (nearby.length > 0) {
            store.dispatch(chooseGeolocation(nearby));
          }
        })
        .catch(noop);
    }

    // NOTE: Home page is no longer accessible - redirect to products
    if (location && location.pathname === urls.home) {
      return <Redirect to={urls.products} />;
    }

    // Update 'category' global state variable using 'product_type' query parameters in URL
    const categoriesQuery = prop(URLParam.productType, urlParams);
    if (categoriesQuery) {
      // categoriesQuery will be a string if only 1 query variable is requested, and an array if > 1 queries are made
      const categoriesWithRegularNameFormat = Array.isArray(categoriesQuery)
        ? categoriesQuery.map(categoryName => convertsUrlStandardToRegularName(categoryName))
        : [convertsUrlStandardToRegularName(String(categoriesQuery))];
      query(categoryListQuery)
        .then(categories => {
          const validCategories = categories.filter(category =>
            categoriesWithRegularNameFormat.includes(convertAnyNameToRegularName(category.name))
          );
          // Do not save query information into browser history, otherwise "source of truth" conflicts occur while navigating
          // Source of truth for filters and queries will be the chosen filter parameters in the UI
          history.replace(location.pathname);
          store.dispatch(chooseCategories(validCategories));
        })
        .catch(noop);
    }
  } catch (e) {
    console.warn('failed to process parameter redirect');
  }
  return <Route {...rest} component={component} />;
};

const mapStateToProps = state => ({
  loggedIn: selectLoggedIn(state),
});

type Outter = {|
  // NOTE(Barry): Not sure why flow is deciding that this is an error now, but it is. Just ignore it
  // $ExpectError
  component: Component<mixed>,
  path: string,
  exact?: boolean,
  location?: {
    search: string,
    pathname: string,
  },
|};
const mapDispatchToProps = {
  chooseActivity,
  clear,
};
const enhancer: HOC<*, Outter> = compose(
  withRouter,
  withConnect(mapStateToProps, mapDispatchToProps)
);

export default enhancer(LoggedOutRoute);
