// @flow strict-local
import Select from 'components/Select';
import type {BundleComponent} from 'data/bundle/types';
import type {ID} from 'data/enums/types';
import {keys} from 'ramda';
import React from 'react';

import {BundleComponentLabel} from './styled';

type Props = {
  bundleComponentsSortedByProduct: {
    // Should be ID but flow gives warning saying it should be string
    [string]: BundleComponent[],
  },
  selectedBundleComponents: {
    [ID]: BundleComponent,
  },
  onSelectBundleComponent: (productId: ID, componentId: ID) => typeof undefined,
};

const BundleSelect = ({
  bundleComponentsSortedByProduct,
  selectedBundleComponents,
  onSelectBundleComponent,
}: Props) => {
  return (
    <React.Fragment>
      {keys(bundleComponentsSortedByProduct).map((productId, index) => {
        const productName =
          bundleComponentsSortedByProduct[productId][0].productVariant.product.name;
        const bundleComponentList = bundleComponentsSortedByProduct[productId];
        const bundleComponentOptions = bundleComponentList.map(component => ({
          id: component.id,
          name: component.productVariant.name,
        }));
        return (
          <React.Fragment key={index}>
            <BundleComponentLabel>{productName}</BundleComponentLabel>
            <Select
              onChange={componentId => {
                onSelectBundleComponent(
                  productId,
                  typeof componentId === 'number' ? componentId : Number(componentId)
                );
              }}
              options={bundleComponentOptions}
              value={selectedBundleComponents[productId].id}
            />
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
};

export default BundleSelect;
