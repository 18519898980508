// @flow
import ArrowIcon from 'assets/icons/ArrowIcon';
import {preventDefault} from 'common/events';
import {Arrow, Container, EmptyOption, Option, StyledSelect} from 'componentsStyled/Forms/Select';
import type {EnumValue, ID} from 'data/enums/types';
import React from 'react';

type Props = {|
  options: EnumValue[],
  onChange: ID => mixed,
  placeholder?: string,
  value: ID,
  name?: string,
|};

const Select = ({options, placeholder, value, allowEmpty, onChange, name}: Props) => (
  <Container>
    <StyledSelect
      empty={value === ''}
      value={value}
      onClick={preventDefault}
      onChange={e => onChange(e.target.value)}
      name={name}
    >
      {placeholder && <EmptyOption value="">{placeholder}</EmptyOption>}
      {options.map(o => (
        <Option key={o.id} value={o.id}>
          {o.name}
        </Option>
      ))}
    </StyledSelect>
    <Arrow>
      <ArrowIcon />
    </Arrow>
  </Container>
);

export default Select;
