// @flow strict-local
import ProductList from 'components/ProductList';
import {Space} from 'componentsStyled/Layout/Spacers';
import {H3} from 'componentsStyled/Typography';
import type {ID} from 'data/enums/types';
import {listPopularRelatedProductsByReservationCount} from 'data/product/graphql/queries';
import type {ProductOffering, RelatedCategory} from 'data/product/types';
import withQuery from 'hoc/withQuery';
import React from 'react';
import {type HOC, compose, withProps} from 'recompose';

import {TitleWrap} from './styled';

type Outter = {
  categoryName: string,
  productId?: ID,
  // HACK(ray>jude): Currently we are showing Related Products on the Basket
  // Page (see TODO in `ShoppingBasket`). So this flag is a convenient way to
  // indicate which heading to render. But the end state should have 2 different
  // component (which looks similar and might share some logic via a shared
  // file), which performs the correct query and renders the right heading.
  isInsideBasket: boolean,
  maxTilesPerRow?: number,
};

/**
 * Page to show a product and its variants offered by an affiliate.
 * User gets to select the variant and create a reservation.
 */
type Props = Outter & {
  relatedCategories: RelatedCategory[],
};
const RelatedProducts = ({relatedCategories, isInsideBasket, maxTilesPerRow}: Props) => {
  let targetSize = 8;
  const productsCount = relatedCategories.reduce(
    (sum, rpg) => sum + rpg.productOfferings.length,
    0
  );
  if (productsCount < 8) {
    targetSize = 4;
  }
  if (productsCount < 4) {
    targetSize = productsCount;
  }

  // Construct number of products from each category in a round robin fashion
  const productOfferingCounts = relatedCategories.map(() => 0);
  for (let i = 0; productOfferingCounts.reduce((p, n) => p + n, 0) < targetSize; i++) {
    const index = i % productOfferingCounts.length;
    // console.log('1.1', i, index)
    if (productOfferingCounts[index] >= relatedCategories[index].productOfferings.length) {
      continue;
    }
    productOfferingCounts[index]++;
  }

  let productOfferings: ProductOffering[] = relatedCategories
    .map((rpg, idx) => {
      return rpg.productOfferings.slice(0, productOfferingCounts[idx]);
    })
    .reduce((prev, next) => prev.concat(next), []);

  if (productOfferings.length < 8) {
    productOfferings = productOfferings.slice(0, 4);
  }

  if (productOfferings.length === 0) {
    return null;
  } else {
    return (
      <React.Fragment>
        {!isInsideBasket ? (
          <TitleWrap>
            <H3>Related Products</H3>
          </TitleWrap>
        ) : (
          <React.Fragment>
            <H3 black strong>
              You might also like....
            </H3>
            <Space />
          </React.Fragment>
        )}
        <ProductList productOfferings={productOfferings} maxTilesPerRow={maxTilesPerRow} />
      </React.Fragment>
    );
  }
};

const enhancer: HOC<*, Outter> = compose(
  withQuery(listPopularRelatedProductsByReservationCount, {
    variables: props => ({
      limit: 99,
      category: props.categoryName,
      productId: props.productId,
    }),
  }),
  withProps(ownerProps => {
    return {relatedCategories: ownerProps.data.relatedCategories};
  })
);

export default enhancer(RelatedProducts);
