// @flow
import type {Selector} from 'common/redux/types';
import {compose, prop} from 'ramda';

import type {AppState} from './reducer';
import type {Activity, AppConfig, Category, Location, Theme} from './types';

const base: Selector<AppState> = prop('app');

export const selectAppConfig: Selector<?AppConfig> = compose(prop('appConfig'), base);

export const selectTheme: Selector<?Theme> = compose(prop('theme'), base);

export const selectActivity: Selector<?Activity> = compose(prop('activity'), base);

export const selectAllCategories: Selector<?(Category[])> = compose(prop('allCategories'), base);

export const selectCategories: Selector<?(Category[])> = compose(prop('categories'), base);

export const selectGeolocation: Selector<?(Location[])> = compose(prop('geolocation'), base);

export const selectGDPRHidden: Selector<?boolean> = compose(prop('GDPRHidden'), base);

export const selectRedirectCallback: Selector<?Function> = compose(prop('redirectCallback'), base);
