// @flow
import Checkbox from 'components/Checkbox';
import {Text} from 'componentsStyled/Typography/Texts';
import {affiliateSupportedCategoriesQuery} from 'data/affiliate/graphql';
import {selectActivity, selectAllCategories, selectCategories} from 'data/app/selectors';
import type {Category} from 'data/app/types';
import {closeModal} from 'data/modals/actions';
import {selectSearchParams} from 'data/search/selectors';
import withConnect from 'hoc/withConnect';
import withOpen from 'hoc/withOpen';
import withQuery from 'hoc/withQuery';
import React from 'react';
import {type HOC, compose, withHandlers} from 'recompose';
import {MultiselectItem} from 'searchFilters/Shared/Multiselect/styled';

type Props = {|
  /** All categories as defined in the backend */
  allCategories: Category[],
  /** Selected categories */
  value: Category[],
  /** Callback to set selected categories */
  onChange: (Category[]) => mixed,
  /** Categories supported by the affiliate (if on affiliate page) */
  data: ?(Category[]),
|};

/**
 * Renders list of selectable categories appropriate for the page, i.e.
 * main search page shows all groups and affiliate page shows one supported by
 * the affiliate.
 */
const CategoryFilter = ({allCategories, value, handleChooseCategory, data}) => {
  /** Filter out ones not supported by the affiliate */
  const selectableCategories = allCategories.filter((category: Category) => {
    if (!data) {
      return true;
    }
    return data.some(pg => pg.name === category.name);
  });

  return (
    <React.Fragment>
      {selectableCategories.map(selectableCategory => {
        const active =
          value && value.map(v => v.categoryId).includes(selectableCategory.categoryId);
        return (
          <MultiselectItem
            key={selectableCategory.categoryId}
            onClick={() => handleChooseCategory(selectableCategory)}
          >
            <Text book={active} black={active}>
              {selectableCategory.name}
            </Text>{' '}
            <Checkbox value={active} />
          </MultiselectItem>
        );
      })}
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  activity: selectActivity(state),
  allCategories: selectAllCategories(state),
  categories: selectCategories(state),
  params: selectSearchParams(state),
});

const mapDispatchToProps = {
  closeModal,
};

const enhancer: HOC<*, Props> = compose(
  withConnect(mapStateToProps, mapDispatchToProps),
  withQuery(affiliateSupportedCategoriesQuery, {
    variables: props => ({
      id: props.params.affiliate,
    }),
    noEmpty: true,
    config: {
      skip: props => !props.params.affiliate,
    },
  }),
  withOpen,
  withHandlers({
    handleChooseCategory: props => (selectedCategory: Category) => {
      const prevValue = props.value || [];

      if (prevValue.map(x => x.categoryId).includes(selectedCategory.categoryId)) {
        const newValue = prevValue.filter(x => x.categoryId !== selectedCategory.categoryId);
        return props.onChange(newValue);
      }

      return props.onChange(prevValue.concat(selectedCategory));
    },
  })
);

export default enhancer(CategoryFilter);
