// @flow
import ShopIcon from 'assets/icons/ShopIcon';
import TruckIcon from 'assets/icons/TruckIcon';
import ShowMore from 'components/ShowMore';
import Tabs from 'components/Tabs';
import {Space} from 'componentsStyled/Layout/Spacers';
import type {AffiliateMinimal} from 'data/affiliate/types';
import type {FulfillmentType} from 'data/bookings/types';
import type {ProductVariant, ProductVariantAvailableAffiliate} from 'data/product/types';
import Reserve from 'pages/Product/Detail/Reserve';
import {StyledDescription, TabTitle} from 'pages/Product/FulfilmentTabs/styled';
import {path} from 'ramda';
import React from 'react';
// $ReactHooks
import {useState} from 'react';

type Props = {
  affiliate: AffiliateMinimal,
  productVariant: ProductVariant | null,
  variantAffiliate: ProductVariantAvailableAffiliate | null,
  startingFulfilmentTab?: FulfillmentType,
};

const fulfilmentTypeToTabIndex: {[FulfillmentType]: number} = {
  IN_STORE: 0,
  DELIVERY: 1,
};

const FulfilmentTabs = ({
  affiliate,
  productVariant,
  variantAffiliate,
  startingFulfilmentTab = 'IN_STORE',
}: Props) => {
  const {address, deliverySummary, allowInStoreFulfilment, allowDeliveryFulfilment} = affiliate;
  const [currentTabIndex, setCurrentTabIndex] = useState(
    fulfilmentTypeToTabIndex[startingFulfilmentTab] || 0
  );
  //Gives us an array of the fulfilment types (as tab definitions) that have been allowed by the store
  const tabs = [
    allowInStoreFulfilment && {
      title: (
        <TabTitle>
          <ShopIcon /> Pick up
        </TabTitle>
      ),
      content: (
        <>
          <p>{address}</p>
          <Space />
          <Reserve
            fulfillmentType="IN_STORE"
            reservationInput={productVariant}
            variantAffiliate={variantAffiliate}
          />
        </>
      ),
    },
    allowDeliveryFulfilment && {
      title: (
        <TabTitle>
          <TruckIcon /> Delivery
        </TabTitle>
      ),
      content: (
        <>
          <ShowMore maxHeight={'7rem'}>
            <StyledDescription description={deliverySummary} multiLine />
          </ShowMore>
          <Space />
          <Reserve
            fulfillmentType="DELIVERY"
            reservationInput={productVariant}
            variantAffiliate={variantAffiliate}
          />
        </>
      ),
    },
  ].filter(x => x);

  //No fufilment => nothing to show
  if (tabs.length === 0) {
    return null;
  }

  //Only 1 fulfilment type allowed => no tabs
  if (tabs.length === 1) {
    //Really just need tabs[0], but flow doesn't like that
    const tab = path(['0'], tabs) || {};

    return (
      <>
        <div>{tab.title}</div>
        <div>{tab.content}</div>
      </>
    );
  }

  //Else we have multiple fulfilment types => use tabs
  return (
    <Tabs
      currentTabIndex={currentTabIndex}
      onCurrentTabIndexChange={setCurrentTabIndex}
      //$ExpectError | Flow infers the incorrect type for tabs
      tabs={tabs}
      fullWidthTabs={true}
    />
  );
};

export default FulfilmentTabs;
