// @flow
import * as React from 'react';
import {Redirect} from 'react-router';
import type {Component} from 'recompose';

function withRedirect<T>(
  predicate: T => boolean,
  url: string
): (Component: Component<T>) => Component<T> {
  return Component => props => {
    if (predicate(props)) {
      return <Redirect to={url} />;
    }

    return <Component {...props} />;
  };
}

export default withRedirect;
