// @flow
import {BagContext} from 'components/Bag/index';
import BasketItemPreview from 'components/BasketItemPreview';
import {selectBasketItems} from 'data/basket/selectors';
import type {PendingReservation} from 'data/basket/types';
import type {FulfillmentType} from 'data/bookings/types';
import type {ReservationPricingDetails} from 'data/reservations/types';
import withConnect from 'hoc/withConnect';
import {sum} from 'ramda';
// $ReactHooks
import React, {useContext, useMemo} from 'react';
import type {HOC} from 'recompose';
import {compose} from 'recompose';

type Outter = {|
  groupedByDate: string,
  reservationPricingDetails: ReservationPricingDetails,
  fulfillmentType: FulfillmentType,
|};

export type Props = {
  ...Outter,
  items: PendingReservation[],
};

//Wrapper around BasketItemPreview
const BagItem = ({items, reservationPricingDetails, fulfillmentType, groupedByDate}: Props) => {
  const {productVariantId, affiliateId, pricing} = reservationPricingDetails;

  const [start, end] = groupedByDate.split('|');

  // Accessory prices are included in fullPrice, so we must subtract them as they are displayed elsewhere
  // $ExpectError flow doesn't know flatmap
  const accessoryPrices = pricing.accessories.flatMap(accessory => accessory.prices);
  const accessoryPrice = sum(accessoryPrices || []);
  const displayPrice = pricing.fullPrice - accessoryPrice;

  const {canDelete} = useContext(BagContext);

  const matchingItem = useMemo(() => {
    return items.find(
      item =>
        item.start === start &&
        item.end === end &&
        item.productVariant.id === productVariantId &&
        item.affiliate.affiliateId === affiliateId &&
        item.fulfillmentType === fulfillmentType
    );
  }, [items, start, end, productVariantId, affiliateId, fulfillmentType]);

  return (
    !!matchingItem && (
      <BasketItemPreview
        product={matchingItem.productVariant.product}
        variant={matchingItem.productVariant}
        startDate={matchingItem.start}
        endDate={matchingItem.end}
        canDelete={canDelete}
        timeCreated={matchingItem.timeCreated}
        fullPrice={displayPrice}
        totalDays={pricing.totalDays}
        currency={pricing.currency}
      />
    )
  );
};

const mapStateToProps = state => ({
  items: selectBasketItems(state),
});

const enhancer: HOC<*, Outter> = compose(withConnect(mapStateToProps));

export default enhancer(BagItem);
