// @flow
import {mq} from 'common/mediaQuery';
import {Text} from 'componentsStyled/Typography/Texts';
import styled from 'styled-components';

export const MultiselectItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.1rem solid #f1f3f6;
  padding: 0 2.4rem;
  height: 6rem;
  white-space: nowrap;

  ${mq.tabletWide} {
    border: 0;
    height: 4.5rem;
    cursor: pointer;
    padding: 0 1.6rem;

    & > *:first-child {
      margin-right: 2rem;
    }

    &:hover {
      background: #d9dee6;
    }
  }
`;

// Copied from text and with modifiers added
export const TextWithEllipsis = styled(Text)`
  min-width: 0%;
  max-width: 30rem;
  text-overflow: ellipsis;
  overflow: hidden;
`;
