import styled from 'styled-components';

export const SubGroupWrap = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  justify-content: space-between;

  &:hover {
    cursor: pointer;
  }
`;

export const Expander = styled.div`
  display: flex;
  align-items: center;
`;
