// @flow strict-local
import Select from 'components/Select';
import type {ID} from 'data/enums/types';
import React from 'react';
import {type HOC, compose, withStateHandlers} from 'recompose';

import {VariantVariableLabel} from './styled';

const VariableSelect = ({
  label,
  onChange,
  variantVariableOptions,
  updateSelectedVariantVariables,
  variantVariableId,
}) => {
  // assign key to each variantVariableOption, based on position in array, so that it can work with the select component
  const variableOptions = variantVariableOptions.map((value, index) => {
    return {
      id: index,
      name: value,
    };
  });

  return (
    <div>
      <VariantVariableLabel>{label}</VariantVariableLabel>
      <Select onChange={onChange} options={variableOptions} value={variantVariableId} />
    </div>
  );
};
type Outter = {
  variantVariableOptions: Array<string>,
  label: string,
  updateSelectedVariantVariables: (label: string, variableChoice: string) => void,
};

const enhancer: HOC<*, Outter> = compose(
  withStateHandlers(
    props => {
      return {
        variantVariableId: Number(0),
      };
    },
    {
      onChange: (state, props) => (id: ID) => {
        const variantVariableChoice = props.variantVariableOptions[+id];
        // Inform parent component of change by giving the label changed and the value
        // it is changed to
        props.updateSelectedVariantVariables(props.label, variantVariableChoice);
        return {
          variantVariableId: id,
        };
      },
    }
  )
);

export default enhancer(VariableSelect);
