// @flow strict-local
import Description from 'components/Description';
import {Space} from 'componentsStyled/Layout/Spacers';
import {Text} from 'componentsStyled/Typography/Texts';
import type {AffiliateMinimal} from 'data/affiliate/types';
import {selectAppConfig} from 'data/app/selectors';
import type {AppConfig} from 'data/app/types';
import type {BundleComponent} from 'data/bundle/types';
import type {ID} from 'data/enums/types';
import type {ProductVariantAvailableAffiliate} from 'data/product/types';
import {formatPricing} from 'data/units/money/formatters';
import withConnect from 'hoc/withConnect';
import Reserve from 'pages/Product/Detail/Reserve';
import {keys, last} from 'ramda';
import React from 'react';

type Props = {
  selectedBundleComponents: {
    [ID]: BundleComponent,
  },
  bundleDescription: string,
  affiliate: AffiliateMinimal,
  appConfig: ?AppConfig,
};

const BundleDetail = ({
  selectedBundleComponents,
  bundleDescription,
  affiliate,
  appConfig,
}: Props) => {
  // Create pricing template based on all the chosen bundle componentsn
  const pricingTemplateList = keys(selectedBundleComponents).map(
    productId => selectedBundleComponents[productId].pricingDetails.prices
  );
  const maxPricingTemplateLength = Math.max(...pricingTemplateList.map(array => array.length));

  const pricingTemplateSum = new Array(maxPricingTemplateLength).fill(0);

  pricingTemplateList.forEach(pricingList => {
    for (let i = 0; i < maxPricingTemplateLength; i++) {
      if (i < pricingList.length) {
        pricingTemplateSum[i] += pricingList[i];
      } else {
        // Fill in the rest of the max pricing template length with the last element
        pricingTemplateSum[i] += last(pricingList);
      }
    }
  });

  const productIds = keys(selectedBundleComponents);
  const availability =
    selectedBundleComponents[productIds[0]].productVariant.affiliates[0].availability;

  const bundleComponentsList = keys(selectedBundleComponents).map(
    productId => selectedBundleComponents[productId]
  );

  // variantAffiliate is used to show the price charged day by day
  const variantAffiliate: ProductVariantAvailableAffiliate = {
    affiliateId: affiliate.id,
    name: affiliate.name,
    availability,
    pricing: pricingTemplateSum,
    location: {
      country: {
        currency: affiliate.location.country.currency,
      },
    },
    // FIX(Anyone): Fix accessory selection for bundles when it is to be used.
    hasAccessories: false,
  };

  const currency =
    appConfig && appConfig.showMinimalProductTileInfo
      ? undefined
      : affiliate.location.country.currency;

  return (
    <React.Fragment>
      <Text>
        <Text inline black book>
          {formatPricing(pricingTemplateSum, currency)}
        </Text>{' '}
        per day
      </Text>
      <Space />
      <Reserve reservationInput={bundleComponentsList} variantAffiliate={variantAffiliate} />
      <Space />
      <Description description={bundleDescription} multiLine />
      <Space />
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  appConfig: selectAppConfig(state),
});

export default withConnect(mapStateToProps)(BundleDetail);
