// @flow
import urls from 'data/router/urls';
import AccountPage from 'pages/Account';
import AffiliatePage from 'pages/Affiliate';
import BasketCheckout from 'pages/BasketCheckout';
import BundlePage from 'pages/Bundle';
import CartPage from 'pages/Cart';
import DebugPage from 'pages/Debug';
import DeliveryAddressPage from 'pages/DeliveryAddress';
import ExplorePage from 'pages/Explore';
import HomePage from 'pages/Home';
import MasqueradePage from 'pages/Masquerade';
import NotFoundPage from 'pages/NotFound';
import ProductPage from 'pages/Product';
import ProductsPage from 'pages/Products';
import ReservationPage from 'pages/Reservation';
import ReservationExtensionPage from 'pages/ReservationExtension';
import ReservationsPage from 'pages/Reservations';
import ResetPasswordPage from 'pages/ResetPassword';
import SearchPage from 'pages/Search';
import ShoppingBasketPage from 'pages/ShoppingBasket';
import TermsPage from 'pages/Terms';
import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';

import RentalAgreementPage from '../../../pages/RentalAgreement';
import ReturnConfirmation from '../../../pages/ReturnConfirmation';
import LoggedInRoute from './LoggedInRoute';
import LoggedOutRoute from './LoggedOutRoute';

const Routes = () => (
  <Switch>
    {/* Routes for logged out AND logged in */}
    <LoggedOutRoute exact path={urls.home} component={HomePage} />
    <LoggedOutRoute path={urls.search} component={SearchPage} />
    <LoggedOutRoute path={urls.returnConfirmation} component={ReturnConfirmation} />
    <LoggedOutRoute path={urls.cart(':productVariantId', ':affiliateId')} component={CartPage} />
    <LoggedOutRoute exact path={urls.products} component={ProductsPage} />
    <LoggedOutRoute path={urls.explore} component={ExplorePage} />
    <LoggedOutRoute path={urls.affiliate(':id')} component={AffiliatePage} />
    <LoggedOutRoute path={urls.shoppingBasket} component={ShoppingBasketPage} />
    <LoggedOutRoute path={urls.bundle(':bundleId', ':affiliateId')} component={BundlePage} />
    <LoggedOutRoute path={urls.rentalAgreement} component={RentalAgreementPage} />

    {/* Routes for logged in only */}
    <LoggedInRoute path={urls.account.index} component={AccountPage} />
    <LoggedInRoute exact path={urls.reservations} component={ReservationsPage} />
    <LoggedInRoute path={urls.reservation(':id')} component={ReservationPage} />
    <LoggedInRoute
      path={urls.extendReservation(':productVariantId', ':affiliateId')}
      component={ReservationExtensionPage}
    />
    <LoggedInRoute path={urls.deliveryAddress} component={DeliveryAddressPage} />

    <LoggedInRoute path={urls.basketCheckout} component={BasketCheckout} />

    {/*
     * NOTE: "products" needs to have a lower precedence as it has a more
     * general path form than "cart" and "reserveProduct"
     */}
    <LoggedOutRoute path={urls.product(':productId', ':affiliateId')} component={ProductPage} />

    {/* Special routes that do not incur processing done by `LoggedOutRoute` */}
    <Route
      path={urls.brand(':name')}
      render={props => <Redirect to={`${urls.products}/?brand=${props.match.params.name}`} />}
    />
    <Route path={urls.terms} component={TermsPage} />
    <Route path={urls.resetPassword} component={ResetPasswordPage} />
    <Route path={urls.debug} component={DebugPage} />
    <Route path={urls.masqueradeClaim(':claimToken')} component={MasqueradePage} />

    {/* Redirect deprecated routes to their new counterpart Routes */}
    <Route
      path={urls.reservationDeprecated(':id')}
      render={props => <Redirect to={urls.reservation(props.match.params.id)} />}
    />
    <Route
      exact
      path={urls.cartDeprecated(':productVariantId', ':affiliateId')}
      render={props => (
        <Redirect
          to={urls.cart(props.match.params.productVariantId, props.match.params.affiliateId)}
        />
      )}
    />
    <Route
      path={urls.productDeprecated(':productId', ':affiliateId')}
      render={props => (
        <Redirect to={urls.product(props.match.params.productId, props.match.params.affiliateId)} />
      )}
    />
    <Redirect from={urls.basketCheckoutDeprecated} to={urls.basketCheckout} />
    <Redirect from={urls.shoppingBasketDeprecated} to={urls.shoppingBasket} />

    {/* Catch all 404 */}
    <Route component={NotFoundPage} />
  </Switch>
);

export default Routes;
