// @flow
import type {Query} from 'common/graphql/types';
import type {CategoryName} from 'data/app/types';
import type {EnumValue, ID} from 'data/enums/types';
import gql from 'graphql-tag';
import {indexBy, prop} from 'ramda';

export type FilterableParameter =
  | {|
      name: string,
      type: 'float_value',
    |}
  | {|
      name: string,
      type: 'enum_value',
      enum: {
        multiple: boolean,
        values: EnumValue[],
      },
    |};

export type FilterableParameters = {[string]: FilterableParameter};

// TODO(ray): Support input filters for product listing
export const filterableParametersQuery: Query<
  FilterableParameters,
  {
    categories: ?(CategoryName[]),
    locationIds: ?(ID[]),
    affiliateIds: ?(ID[]),
  }
> = {
  gql: gql`
    query filterableParametersQuery(
      $categories: [String]
      $locationIds: [Int!]
      $affiliateIds: [Int!]
    ) {
      product {
        getFilterableParameters(categories: $categories) {
          name
          type
          enum {
            name
            multiple
            values {
              id
              name: value
              order
            }
          }
        }
        listFilterableProducts(filter: {limit: 9999, availableOnly: true}) {
          data {
            id
            name
          }
        }
      }
      manufacturer {
        listManufacturersForAvailableProducts(
          filter: {
            limit: 9999
            categories: $categories
            locationIds: $locationIds
            affiliateIds: $affiliateIds
          }
        ) {
          data {
            id
            name
          }
        }
      }
    }
  `,
  transform: data => {
    const brands =
      data.manufacturer && data.manufacturer.listManufacturersForAvailableProducts.data;
    const products = data.product && data.product.listFilterableProducts.data;
    // $ExpectError - TODO - transform shouldn't run if there is no data
    if (!brands || !products) return;

    // $ExpectError
    return {
      brand: {
        name: 'brand',
        type: 'enum_value',
        // enum is reserved word in flow
        // prettier-ignore
        "enum": {
          name: "brand",
          multiple: true,
          values: brands,
        },
      },
      product: {
        name: 'product',
        type: 'enum_value',
        enum: {
          name: 'product',
          multiple: true,
          values: products,
        },
      },
      ...indexBy(prop('name'), data.product && data.product.getFilterableParameters),
    };
  },
};
