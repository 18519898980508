// @flow
import CrossIcon from 'assets/icons/CrossIcon';
import ProductDimension from 'components/ProductDimension';
import {Text} from 'componentsStyled/Typography/Texts';
import {SubTitle} from 'componentsStyled/Typography/Titles';
import {removeFromBasket} from 'data/basket/actions';
import {minHeightImg, selectIndexImage} from 'data/images/helpers';
import type {Product, ProductVariant} from 'data/product/types';
import {formatDate} from 'data/units/date/formatters';
import type {DateString, IsoDate} from 'data/units/date/types';
import {formatMoney} from 'data/units/money/formatters';
import withConnect from 'hoc/withConnect';
import React from 'react';
import {type HOC, compose, withHandlers} from 'recompose';

import {
  BasketPreviewWrap,
  Content,
  DeleteIconWrap,
  ImageWrap,
  ItemDetailWrap,
  ItemPricing,
  ItemPricingWrap,
} from './styled';

const BasketItemPreview = ({
  canDelete,
  product,
  variant,
  startDate,
  endDate,
  onRemoveItem,
  key,
  index,
  timeCreated,
  fullPrice,
  totalDays,
  currency,
}) => {
  const indexImage = selectIndexImage(product.images);
  const imageSize = minHeightImg(indexImage, 100);
  return (
    <BasketPreviewWrap canDelete={canDelete} data-cy={'basket-preview'}>
      <ItemDetailWrap>
        <ImageWrap>
          <img src={imageSize.url} alt={indexImage.alt} draggable="false" />
        </ImageWrap>
        <Content>
          <Text>
            {formatDate(startDate)} - {formatDate(endDate)}
          </Text>
          <SubTitle>{product.name}</SubTitle>
          <ProductDimension productVariant={variant} compact />
        </Content>
      </ItemDetailWrap>
      <ItemPricingWrap>
        <ItemPricing>
          {fullPrice && currency && <p>{formatMoney(fullPrice, true, currency)}</p>}
          {totalDays && (
            <p>
              {totalDays} day{totalDays === 1 ? '' : 's'}
            </p>
          )}
        </ItemPricing>
        {canDelete && (
          <DeleteIconWrap small onClick={onRemoveItem}>
            <CrossIcon />
          </DeleteIconWrap>
        )}
      </ItemPricingWrap>
    </BasketPreviewWrap>
  );
};

const mapDispatchToProps = {
  removeFromBasket,
};

type Outter = {
  product: Product,
  variant: ProductVariant,
  startDate: DateString,
  endDate: DateString,
  index?: number,
  canDelete?: boolean,
  timeCreated?: IsoDate,
  fullPrice?: number,
  totalDays?: number,
  currency?: string,
};

const enhancer: HOC<*, Outter> = compose(
  withConnect(() => ({}), mapDispatchToProps),
  withHandlers({
    onRemoveItem: props => event => {
      if (props.canDelete && props.timeCreated) {
        props.removeFromBasket(props.timeCreated);
      }
    },
  })
);

export default enhancer(BasketItemPreview);
