// @flow
import NearbyIcon from 'assets/icons/NearbyIcon';
import {Text} from 'componentsStyled/Typography/Texts';
import {geolocationListQuery} from 'data/app/graphql/queries';
import {selectCategories} from 'data/app/selectors';
import type {Location} from 'data/app/types';
import withConnect from 'hoc/withConnect';
import withQuery from 'hoc/withQuery';
import {groupBy, keys, prop, sortBy} from 'ramda';
import React from 'react';
import {type HOC, compose} from 'recompose';

import {Group, IconWrap, Item} from './styled';

const LocationFilter = ({data, onChange}) => {
  const sortedLocations = sortBy(prop('name'), data);
  const groups = groupBy((x: Location) => x.country.name, sortedLocations);
  const sortedCountryNames = keys(groups).sort();
  const nearby = data.filter(loc => loc.nearby);

  return (
    <React.Fragment>
      <Text black book nowrap onClick={() => onChange(nearby)} style={{cursor: 'pointer'}}>
        <IconWrap>
          <NearbyIcon />
        </IconWrap>
        Nearby
      </Text>
      {nearby.map((loc, idx) => (
        <Item onClick={() => onChange([loc])} key={idx} nowrap>
          {loc.name}
        </Item>
      ))}

      {sortedCountryNames.map(g => (
        <Group key={g}>
          <Text black book nowrap>
            {g}
          </Text>
          {groups[g].map(l => (
            <Item key={l.name} onClick={() => onChange([l])} nowrap>
              {l.name}
            </Item>
          ))}
        </Group>
      ))}
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  categories: selectCategories(state),
});

type Outter = {|
  onChange: (Location[]) => mixed,
  value: Location,
|};

const enhancer: HOC<*, Outter> = compose(
  withConnect(mapStateToProps),
  withQuery(geolocationListQuery, {
    variables: props => ({
      categories: props.categories ? props.categories.map(category => category.name) : undefined,
    }),
  })
);

export default enhancer(LocationFilter);
