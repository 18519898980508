// @flow
import {isEmpty, isNil, values} from 'ramda';
import * as React from 'react';

import {FilterInputWrap} from './styled';

type Props = {|
  value: any,
  placeholder: string,
  onClick: Function,
  formatter: Function,
|};

const FilterInput = ({value, placeholder, onClick, formatter}: Props) => {
  const empty =
    isNil(value) || isEmpty(value) || (!isEmpty(values(value)) && values(value).every(isNil));

  return (
    <FilterInputWrap empty={empty} onClick={onClick}>
      {empty ? placeholder : formatter(value)}
    </FilterInputWrap>
  );
};

export default FilterInput;
