// @flow
import ErrorOutlineIcon from 'assets/icons/ErrorOutlinedIcon';
import {BagWarningWrap} from 'components/BagWarning/styled';
import {getUniqueCurrencies} from 'data/basket/helpers';
import type {PendingReservation} from 'data/basket/types';
import {getSymbol} from 'data/units/money/formatters';
import {uniqBy} from 'ramda';
import React from 'react';

export type Props = {
  items: PendingReservation[],
};

const getMultipleCurrencyMessage = (items: PendingReservation[]) => {
  const uniqueCurrencies = getUniqueCurrencies(items);

  //If not multiple currencies then no warning message
  if (uniqueCurrencies.length <= 1) {
    return null;
  }

  const [firstSymbol, ...restSymbols] = uniqueCurrencies.map(
    currency => `${getSymbol(currency)}${currency}`
  );

  return `You’re renting items in ${restSymbols.join(
    ', '
  )} & ${firstSymbol}. Please make sure you only
   have items in one currency in order to check out.`;
};

const getMultipleShopAndDatesMessage = (items: PendingReservation[]) => {
  const hasMultipleStores = uniqBy(({affiliate}) => affiliate.affiliateId, items).length > 1;
  //Has reservations across multiple date periods
  const hasMultipleDatePeriods =
    uniqBy(({start, end}) => `${String(start)}|${String(end)}`, items).length > 1;

  // If not multiple stores or date periods then no warning to show
  if (!(hasMultipleStores || hasMultipleDatePeriods)) {
    return null;
  }

  const warningMessage = [
    hasMultipleStores && 'more than one store',
    hasMultipleDatePeriods && 'more than one date period',
  ]
    .filter(Boolean)
    .join(' and ');

  return `You're renting items from ${warningMessage}.`;
};

const BagWarning = ({items}: Props) => {
  // If no items then no warning to show
  if (!items || items.length < 1) {
    return null;
  }

  const warningMessage = [
    getMultipleCurrencyMessage(items),
    getMultipleShopAndDatesMessage(items),
  ].find(Boolean);

  if (!warningMessage) {
    return null;
  }

  return (
    <BagWarningWrap>
      <ErrorOutlineIcon /> {warningMessage}
    </BagWarningWrap>
  );
};

export default BagWarning;
