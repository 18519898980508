// @flow
import type {Query} from 'common/graphql/types';
import gql from 'graphql-tag';

import type {AppConfig, Category, CategoryName, Location, Theme} from '../types';
import {locationFragment} from './fragments';

export const appConfigQuery: Query<AppConfig> = {
  gql: gql`
    query appConfigQuery {
      app {
        getConfig {
          tenantName
          tenantTitle
          showMinimalProductTileInfo
          enableBundles
        }
      }
    }
  `,
  options: () => ({
    fetchPolicy: 'network-only',
  }),
  selector: ['app', 'getConfig'],
};

export const themeQuery: Query<Theme> = {
  gql: gql`
    query themeQuery {
      theme {
        getTheme {
          enableStoreCarousel
          advancedHeader
          header {
            html
            css
          }
          footer {
            html
            css
          }
          colors {
            primaryAccent
            primaryAccentDark
            primaryText
            secondaryText
            background
          }
          borderRadiuses {
            button
            filterButton
            input
          }
          fonts {
            externalFontCssUrl
            bodyFontFamily
            headingFontFamily
          }
          logo {
            logo
            logoLink
          }
        }
      }
    }
  `,
  options: () => ({
    fetchPolicy: 'network-only',
  }),
  selector: ['theme', 'getTheme'],
};

export const geolocationListQuery: Query<Location[], {categories: ?(CategoryName[])}> = {
  gql: gql`
    query locationsListQuery($categories: [String]) {
      location {
        listLocations(filter: {categories: $categories}) {
          ...locationFragment
          country {
            name
          }
          distance
          nearby
        }
      }
    }
    ${locationFragment}
  `,
  options: () => ({
    fetchPolicy: 'network-only',
  }),
  selector: ['location', 'listLocations'],
};

export const locationByNameQuery: Query<Location, {name: string}> = {
  gql: gql`
    query locationByNameQuery($name: String!) {
      location {
        detailByName(name: $name) {
          ...locationFragment
        }
      }
    }
    ${locationFragment}
  `,
  selector: ['location', 'detailByName'],
};

export const categoryListQuery: Query<Category[]> = {
  gql: gql`
    query categoryListQuery {
      category {
        listForAvailableProducts {
          categoryId
          name
          discountOptOuts {
            id
            discountId
          }
        }
      }
    }
  `,
  selector: ['category', 'listForAvailableProducts'],
};
