// @flow
import Desktop from 'components/Media/Desktop';
import Mobile from 'components/Media/Mobile';
import {chooseCategories, chooseGeolocation} from 'data/app/actions';
import {selectCategories, selectGeolocation} from 'data/app/selectors';
import {openModal} from 'data/modals/actions';
import {clear, filter} from 'data/search/actions';
import {filterableParametersQuery} from 'data/search/graphql/queries/params';
import {selectSearchParams} from 'data/search/selectors';
import withConnect from 'hoc/withConnect';
import withQuery from 'hoc/withQuery';
import React from 'react';
import {type HOC, compose} from 'recompose';

import DesktopFilters from './DesktopFilters';
import MobileFilters from './MobileFilters';
import {MainFilterWrap} from './styled';

const MainFilter = ({
  geolocation,
  categories,
  chooseGeolocation,
  chooseCategories,
  params,
  clear,
  filter,
  data,
}) => {
  const multipleBrands =
    data && data.brand.type === 'enum_value' && data.brand.enum.values.length > 1;

  return (
    <MainFilterWrap>
      <Mobile>
        <MobileFilters
          geolocation={geolocation}
          categories={categories}
          chooseGeolocation={chooseGeolocation}
          chooseCategories={chooseCategories}
          filter={filter}
          params={params}
          multipleBrands={multipleBrands}
        />
      </Mobile>
      <Desktop>
        <DesktopFilters
          geolocation={geolocation}
          categories={categories}
          chooseGeolocation={chooseGeolocation}
          chooseCategories={chooseCategories}
          params={params}
          filter={filter}
          clear={clear}
          multipleBrands={multipleBrands}
        />
      </Desktop>
    </MainFilterWrap>
  );
};

const mapStateToProps = state => ({
  params: selectSearchParams(state),
  geolocation: selectGeolocation(state),
  categories: selectCategories(state),
});

const mapDispatchToProps = {
  filter,
  clear,
  chooseGeolocation,
  chooseCategories,
  openModal,
};

const enhancer: HOC<*, {||}> = compose(
  withConnect(mapStateToProps, mapDispatchToProps),
  withQuery(filterableParametersQuery, {
    variables: props => ({
      categories: props.categories && props.categories.map(category => category.name),
      locationIds: props.geolocation && props.geolocation.map(geo => geo.id),
      affiliateIds: props.params.affiliate ? [props.params.affiliate] : undefined,
    }),
    noLoader: true,
    noEmpty: true,
  })
);

export default enhancer(MainFilter);
