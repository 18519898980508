// @flow
import {Wrap} from 'componentsStyled/Layout/Wrap';
import {Text} from 'componentsStyled/Typography/Texts';
import styled from 'styled-components';

export const EnumFilterWrap = styled(Wrap)`
  display: flex;
  justify-content: space-between;
`;

export const TextWrapper = styled(Text)`
  max-width: 20rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
