// @flow
import {trackProductView} from 'analytics/product/analyticsTraits';
import AffiliateName from 'components/AffiliateName';
import Desktop from 'components/Media/Desktop';
import Mobile from 'components/Media/Mobile';
import RelatedProducts from 'components/RelatedProducts';
import Select from 'components/Select';
import {Container} from 'componentsStyled/Layout/Containers';
import {Flex} from 'componentsStyled/Layout/Flex';
import {Space} from 'componentsStyled/Layout/Spacers';
import {Text} from 'componentsStyled/Typography/Texts';
import {SubTitle, Title} from 'componentsStyled/Typography/Titles';
import {selectAppConfig} from 'data/app/selectors';
import type {ID} from 'data/enums/types';
import {listProductVariantsQuery} from 'data/product/graphql/queries';
import urls from 'data/router/urls';
import {clearProduct} from 'data/search/actions';
import withConnect from 'hoc/withConnect';
import withOnMount from 'hoc/withOnMount';
import withQuery from 'hoc/withQuery';
import withUser from 'hoc/withUser';
import SubPage from 'pages/_Page/SubPage';
import {omit} from 'ramda';
import React from 'react';
import {type HOC, compose, mapProps, withProps, withStateHandlers} from 'recompose';

import Detail from './Detail';
import {DetailWrap, DetailWrapNoPadding} from './Detail/styled';
import Gallery from './Gallery';
import {Half} from './styled';
import VariantVariablesSelect from './VariantVariablesSelect';

/**
 * Page to show a product and its variants offered by an affiliate.
 * User gets to select the variant and create a reservation.
 */
const Product = ({
  affiliate,
  productVariants,
  selectedVariantId,
  match,
  user,
  onSelectVariant,
  validProductVariants,
  variantVariables,
  appConfig,
}) => {
  if (productVariants.length === 0) {
    return null;
  }
  // representative product for display purposes
  const representativeVariant = productVariants[0];
  const representativeProduct = representativeVariant.product;
  const representativeAffiliate = affiliate;

  // selected variant entered into detail component
  const selectedVariant = productVariants.find(productVariant => {
    return productVariant.id === selectedVariantId;
  });

  const validVariantOptions = validProductVariants.map(productVariant => ({
    id: productVariant.id,
    name: productVariant.name,
  }));

  const variantSelectComponent = (
    <React.Fragment>
      <Space />
      {!variantVariables ? (
        <Select
          onChange={variantId => {
            onSelectVariant(typeof variantId === 'number' ? variantId : Number(variantId));
          }}
          options={validVariantOptions}
          value={selectedVariantId}
        />
      ) : (
        <VariantVariablesSelect
          variantVariables={variantVariables}
          productVariants={validProductVariants}
          onSelectVariant={onSelectVariant}
        />
      )}
      <Space />
    </React.Fragment>
  );

  const contentComponent = (
    <React.Fragment>
      <DetailWrap>
        <Text bigOnDesktop>{representativeProduct.manufacturer.name}</Text>
        <Title>{representativeProduct.name}</Title>
        <AffiliateName name={representativeAffiliate.name} />
        {
          // If there are no valid variant options due to data inconsistency,
          // then show 'Product in construction' message
          !!validProductVariants.length ? (
            <React.Fragment>
              {variantSelectComponent}
              {/*TODO(Declan): Why do we use the affiliate on the variant (variantAffiliate) and not the actual affiliate? */}
              <Detail
                productVariant={selectedVariant}
                representativeAffiliate={representativeAffiliate}
                variantAffiliate={selectedVariant ? selectedVariant.affiliates[0] : null}
                user={user}
                productDescription={representativeProduct.description}
              />
            </React.Fragment>
          ) : (
            <SubTitle>Product coming soon</SubTitle>
          )
        }
      </DetailWrap>
    </React.Fragment>
  );

  // TODO Handle multiple categories
  const relatedProductsComponent = (
    <RelatedProducts
      categoryName={representativeProduct.categories[0].name}
      productId={representativeProduct.id}
      isInsideBasket={false}
    />
  );

  return (
    <SubPage title={representativeProduct.name} backUrl={urls.products}>
      <Gallery images={representativeProduct.images} />
      <Mobile>
        {contentComponent}
        <DetailWrapNoPadding>{relatedProductsComponent}</DetailWrapNoPadding>
      </Mobile>
      <Desktop>
        <Container>
          <Flex>
            <Half />
            <Half white>{contentComponent}</Half>
          </Flex>
          <Space />
          {relatedProductsComponent}
        </Container>
      </Desktop>
    </SubPage>
  );
};

type Outter = {|
  match: {|
    params: {|
      productId: ID,
      affiliateId: ID,
    |},
  |},
|};

const mapDispatchToProps = {
  clearProduct,
};

const mapStateToProps = state => ({
  appConfig: selectAppConfig(state),
});

const enhancer: HOC<*, Outter> = compose(
  withUser(),
  withQuery(listProductVariantsQuery, {
    variables: props => ({
      productId: props.match.params.productId,
      affiliateId: props.match.params.affiliateId,
    }),
  }),
  withProps(ownerProps => {
    // Inconsistent variant variable names possible, so create a list of  valid product variants
    // TODO(Jude): Fix up error that occurs here when no tenant is selected
    const variantVariables = ownerProps.data.productVariants[0].product.variantVariables;
    const variantList = ownerProps.data.productVariants;

    const validProductVariants = variantList.filter(variant => {
      const variantItemNames = variant.name.split(' | ');
      // If no variant variables, then behave as normal
      const variantVariablesLength = variantVariables ? variantVariables.split(' | ').length : 1;
      return variantItemNames.length === variantVariablesLength;
    });
    return {
      ...ownerProps.data,
      validProductVariants: validProductVariants,
      variantVariables: variantVariables,
    };
  }),
  mapProps(omit(['data'])), // Clear data field for next query for typechecking
  withStateHandlers(
    props => {
      return {
        // default to first valid variant, if no valid variants default to null
        selectedVariantId: props.validProductVariants.length
          ? props.validProductVariants[0].id
          : null,
      };
    },
    {
      onSelectVariant: (state, props) => variantId => {
        const variant = props.productVariants.find(variant => variant.id === variantId);
        if (variant) {
          trackProductView(variant, props.user);
        }
        return {
          selectedVariantId: variantId,
        };
      },
    }
  ),
  withConnect(mapStateToProps, mapDispatchToProps),
  withOnMount(props => {
    trackProductView(props.productVariants[0], props.user);
    props.clearProduct();
  })
);

export default enhancer(Product);
