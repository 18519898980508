// @flow
import {withRouter as reactRouterHOC} from 'react-router';
import type {Component} from 'recompose';

type Added = {|
  history: {
    push: (string: string, state: any) => void,
    replace: string => void,
  },
  location: {
    search: string,
    pathname: string,
  },
|};

// $ExpectError
function withRouter<T>(C: Component<{...$Exact<T>, ...$Exact<Added>}>): Component<T> {
  return reactRouterHOC(C);
}

export default withRouter;
