// @flow
import {Space} from 'componentsStyled/Layout/Spacers';
import {Text} from 'componentsStyled/Typography/Texts';
import {SubTitle} from 'componentsStyled/Typography/Titles';
import type {AffiliateMinimal} from 'data/affiliate/types';
import {selectAppConfig} from 'data/app/selectors';
import type {AppConfig} from 'data/app/types';
import {selectBasketItems} from 'data/basket/selectors';
import type {PendingReservation} from 'data/basket/types';
import {formatVariantParam} from 'data/product/formatters';
import type {ProductVariant, ProductVariantAvailableAffiliate} from 'data/product/types';
import {formatPricing} from 'data/units/money/formatters';
import type {User} from 'data/user/types';
import withConnect from 'hoc/withConnect';
import {StyledDescription} from 'pages/Product/Detail/styled';
import FulfilmentTabs from 'pages/Product/FulfilmentTabs';
import {isEmpty} from 'ramda';
import React from 'react';

type Props = {
  productVariant: ProductVariant | null,
  variantAffiliate: ProductVariantAvailableAffiliate | null,
  user: User,
  productDescription: string,
  appConfig: ?AppConfig,
  representativeAffiliate: AffiliateMinimal,
  items: PendingReservation[],
};

const Detail = ({
  productVariant,
  variantAffiliate,
  user,
  productDescription,
  appConfig,
  representativeAffiliate,
  items,
}: Props) => {
  // Set the initial fulfilment tab based on the last item added to the cart
  const initialFulfilmentTab =
    items && items.length ? items[items.length - 1].fulfillmentType : 'IN_STORE';
  const pricing = variantAffiliate && variantAffiliate.pricing;
  const binding = productVariant ? formatVariantParam('binding')(productVariant.parameters) : null;

  const currency =
    appConfig && appConfig.showMinimalProductTileInfo && variantAffiliate
      ? undefined
      : variantAffiliate
      ? variantAffiliate.location.country.currency
      : undefined;

  return (
    <React.Fragment>
      {!isEmpty(binding) && <SubTitle>{binding}</SubTitle>}
      {variantAffiliate && pricing && (
        <Text>
          <Text inline black book>
            {formatPricing(pricing, currency)}
          </Text>{' '}
          per day
        </Text>
      )}
      <Space />
      <FulfilmentTabs
        startingFulfilmentTab={initialFulfilmentTab}
        productVariant={productVariant}
        variantAffiliate={variantAffiliate}
        affiliate={representativeAffiliate}
      />
      <Space />
      <StyledDescription description={productDescription} multiLine />
      <Space />
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  appConfig: selectAppConfig(state),
  items: selectBasketItems(state),
});

export default withConnect(mapStateToProps)(Detail);
