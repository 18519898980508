// @flow
import Checkbox from 'components/Checkbox';
import type {EnumValue} from 'data/enums/types';
import React from 'react';
import {type HOC, withHandlers} from 'recompose';

import {MultiselectItem, TextWithEllipsis} from './styled';

const Multiselect = ({options, value, handleSelect}) => (
  <React.Fragment>
    {options.map(o => {
      const active = value && value.map(v => v.id).includes(o.id);
      return (
        <MultiselectItem key={o.id} onClick={() => handleSelect(o)}>
          <TextWithEllipsis book={active} black={active}>
            {o.name}
          </TextWithEllipsis>{' '}
          <Checkbox value={active} />
        </MultiselectItem>
      );
    })}
  </React.Fragment>
);

type Outter = {|
  options: EnumValue[],
  onChange: (?(EnumValue[])) => mixed,
  value: ?(EnumValue[]),
|};

const enhancer: HOC<*, Outter> = withHandlers({
  handleSelect: props => (v: EnumValue) => {
    const prevValue = props.value || [];

    if (prevValue.map(x => x.id).includes(v.id)) {
      const newValue = prevValue.filter(x => x.id !== v.id);
      return props.onChange(newValue);
    }

    return props.onChange(prevValue.concat(v));
  },
});

export default enhancer(Multiselect);
