// @flow
import {Text} from 'componentsStyled/Typography/Texts';
import type {ProductVariant} from 'data/product/types';
import React from 'react';

import {getDimensionText} from './helpers';

type Props = {|
  productVariant: ProductVariant,
  compact?: boolean,
  tile?: boolean,
  center?: boolean,
|};

const ProductDimension = ({productVariant, compact, tile, center = false}: Props) => {
  if (compact) {
    return (
      <Text black center={center} small>
        {getDimensionText(productVariant, false, center, true)}
      </Text>
    );
  }

  return (
    <Text black center={center} bigOnDesktop>
      {getDimensionText(productVariant, true, center)}
    </Text>
  );
};

export default ProductDimension;
