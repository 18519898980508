// @flow
import {trackCategory} from 'analytics/app/analyticsTraits';
import {Wrap} from 'componentsStyled/Layout/Wrap';
import withUser from 'hoc/withUser';
import ModalBody from 'modals/_Body';
import ModalContent from 'modals/_Content';
import ModalHeader from 'modals/_Header';
import * as React from 'react';
import {type Component, type HOC, compose, withHandlers} from 'recompose';

const CustomComponent = ({title, close, component, handleChange, handleClear, value}) => {
  const C = component;

  return (
    <ModalBody close={close}>
      <ModalHeader close={close} title={title} clear={handleClear} />
      <ModalContent>
        <Wrap>
          <C onChange={handleChange} value={value} />
        </Wrap>
      </ModalContent>
    </ModalBody>
  );
};

type Outter = {|
  title: string,
  close: Function,
  component: Component<{|
    onChange: Function,
    value: any,
  |}>,
  onChange: Function,
  value?: any,
|};

const enhancer: HOC<*, Outter> = compose(
  withUser(),
  withHandlers({
    handleChange: props => v => {
      trackCategory(v, props.user);
      props.onChange(v);
      props.close();
    },
    handleClear: props => () => {
      props.close();
      props.onChange(undefined);
    },
  })
);

export default enhancer(CustomComponent);
