// @flow
import Button from 'components/Button';
import type {FulfillmentType} from 'data/bookings/types';
import type {BundleComponent} from 'data/bundle/types';
import type {ID} from 'data/enums/types';
import {openModal} from 'data/modals/actions';
import {notificationError} from 'data/notifications/actions';
import type {ProductVariant, ProductVariantAvailableAffiliate} from 'data/product/types';
import urls from 'data/router/urls';
import {selectSearchParams} from 'data/search/selectors';
import withConnect from 'hoc/withConnect';
import withRouter from 'hoc/withRouter';
import withUser from 'hoc/withUser';
import ProductAvailability from 'modals/ProductAvailability';
import * as React from 'react';
import {type HOC, compose, withHandlers} from 'recompose';

const Reserve = ({handleReserve, handlePickDates, datesSelected}) => (
  <Button fullwidth onClick={handlePickDates} data-cy={'reserve-button'}>
    Try It
  </Button>
);

const mapStateToProps = state => ({
  searchParams: selectSearchParams(state),
});

const mapDispatchToProps = {
  openModal,
  notificationError,
};

type Outter = {|
  reservationInput: BundleComponent[] | ProductVariant | null,
  variantAffiliate: ProductVariantAvailableAffiliate | null,
  fulfillmentType?: FulfillmentType,
|};

const enhancer: HOC<*, Outter> = compose(
  withUser(),
  withRouter,
  withConnect(mapStateToProps, mapDispatchToProps),
  withHandlers({
    handlePickDates: props => () => {
      if (props.reservationInput === null || props.variantAffiliate === null) {
        props.notificationError('Variant variables unavailable');
      } else {
        return props.openModal(ProductAvailability, {
          fulfillmentType: props.fulfillmentType,
          availabilityInput: props.reservationInput,
          variantAffiliate: props.variantAffiliate,
          // function should only be called when there is one productVariant in the array
          callback: () => {
            if (
              !Array.isArray(props.reservationInput) &&
              props.reservationInput &&
              props.reservationInput.id &&
              props.variantAffiliate &&
              props.variantAffiliate.affiliateId
            ) {
              const reservationInputId = (props.reservationInput.id: ID);
              props.history.push(
                urls.cart(reservationInputId, props.variantAffiliate.affiliateId),
                {fulfillmentType: props.fulfillmentType}
              );
            }
          },
        });
      }
    },
  })
);

export default enhancer(Reserve);
