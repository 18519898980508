// @flow
import {Space} from 'componentsStyled/Layout/Spacers';
import {
  selectAppConfig,
  selectCategories,
  selectGeolocation,
  selectTheme,
} from 'data/app/selectors';
import {selectSearchParams} from 'data/search/selectors';
import withConnect from 'hoc/withConnect';
import Page from 'pages/_Page';
import MainFilter from 'pages/_Page/MainFilter';
import React from 'react';
import {type HOC} from 'recompose';

import NearbyOfferings from './NearbyOfferings';
import NearbyProducts from './NearbyProducts';
import NearbyStores from './NearbyStores';

const ProductsPage = ({theme, appConfig, geolocations, categories, params}) => {
  return (
    <Page filter={MainFilter} message={Space}>
      {theme && theme.enableStoreCarousel ? (
        <NearbyStores categories={categories} geolocations={geolocations} params={params} />
      ) : (
        <Space />
      )}
      {appConfig && appConfig.enableBundles ? (
        <NearbyOfferings categories={categories} geolocations={geolocations} />
      ) : (
        <NearbyProducts categories={categories} geolocations={geolocations} />
      )}
    </Page>
  );
};

const mapStateToProps = state => ({
  theme: selectTheme(state),
  appConfig: selectAppConfig(state),
  categories: selectCategories(state),
  geolocations: selectGeolocation(state),
  params: selectSearchParams(state),
});

const enhancer: HOC<*, {||}> = withConnect(mapStateToProps);

export default enhancer(ProductsPage);
