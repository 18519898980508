// @flow
import {locationFragment} from 'data/app/graphql/fragments';
import {manufacturerFragment} from 'data/manufacturer/fragments';
import gql from 'graphql-tag';

const parameters = `
    ... on ParameterFloatValue {
      id
      name
      label
      type
      floatValue
      unit
      displayedUnit
    }
    ... on ParameterIntValue {
      id
      name
      label
      type
      intValue
      unit
      displayedUnit
    }
    ... on ParameterEnumFlattenedValue {
      id
      name
      label
      type
      enumValues
    }
    ... on ParameterStringValue {
      id
      name
      label
      type
      stringValue
    }
`;

export const productFragment = gql`
  fragment productFragment on Product {
    id
    name
    description
    variantVariables
    images {
      alt
      order
      sizes {
        url
        width
        height
      }
    }
    manufacturer {
      ...manufacturerFragment
    }
    categories {
      categoryId
      name
    }
    ratingAverage
    ratingCount
  }
  ${manufacturerFragment}
`;

export const productOfferingFragment = gql`
  fragment productOfferingFragment on ProductOffering {
    productId
    name
    categories {
      categoryId
      name
    }
    manufacturer {
      id
      name
    }
    images {
      alt
      order
      sizes {
        url
        width
        height
      }
    }
    affiliateId
    affiliateName
    pricingDetails {
      prices
    }
    currency
  }
`;

export const productVariantFragment = gql`
  fragment productVariantFragment on ProductVariant {
    id
    name
    parameters {
      ${parameters}
    }
    product {
      ...productFragment
    }
    affiliates {
      affiliateId
      name
      availability {
        startDate: start
        endDate: end
      }
      pricing
      hasAccessories
      location {
        ...locationFragment
      }
    }
  }
  ${productFragment}
  ${locationFragment}
`;

export const productItemFragment = gql`
  fragment productItemFragment on ProductItem {
    id
    productVariant {
      ...productVariantFragment
    }
    code
    affiliate {
      id
      name
      address
    }
  }
  ${productVariantFragment}
`;

export const productVariantMinFragment = gql`
  fragment productVariantMinFragment on ProductVariant {
    id
    parameters {
     ${parameters}
    }
    product {
      ...productFragment
    }
  }
  ${productFragment}
`;

export const productItemMinFragment = gql`
  fragment productItemMinFragment on ProductItem {
    id
    productVariant {
      ...productVariantMinFragment
    }
    code
    affiliate {
      id
      name
      address
    }
  }
  ${productVariantMinFragment}
`;

export const productFragmentWithVariants = gql`
  fragment productFragment on Product {
    id
    name
    description
    images {
      alt
      order
      sizes {
        url
        width
        height
      }
    }
    manufacturer {
      ...manufacturerFragment
    }
    categories {
      categoryId
      name
    }
    variants {
      id
      parameters {
        ${parameters}
      }
    }
  }
  ${manufacturerFragment}
`;
