// @flow
import type {Activity, AppConfig, Category, CategoryName, Location, Theme} from './types';

export type ChooseActivity = {|
  type: 'APP/CHOOSE_ACTIVITY',
  payload: Activity,
|};
export const chooseActivity = (a: Activity): ChooseActivity => ({
  type: 'APP/CHOOSE_ACTIVITY',
  payload: a,
});

export type ChooseCategories = {|
  type: 'APP/CHOOSE_CATEGORY',
  payload: Category[],
|};

export const chooseCategories = (categories: Category[]): ChooseCategories => ({
  type: 'APP/CHOOSE_CATEGORY',
  payload: categories,
});

export const setAppConfig = (appConfig: AppConfig) => ({
  type: 'APP/SET_CONFIG',
  payload: appConfig,
});

export const setTheme = (theme: Theme) => ({
  type: 'APP/SET_THEME',
  payload: theme,
});

export const prefetchGeolocations = (geolocations: {[CategoryName]: Location[]}) => ({
  type: 'APP/PREFETCH_GEOLOCATIONS',
  payload: geolocations,
});

export const setAllCategories = (categories: Category[]) => ({
  type: 'APP/SET_ALL_CATEGORIES',
  payload: categories,
});

export const chooseGeolocation = (g: Location[]) => ({
  type: 'APP/CHOOSE_GEOLOCATION',
  payload: g,
});

export type HideGDPR = {|
  type: 'APP/HIDE_GDPR',
|};
export const hideGDPR = (): HideGDPR => ({
  type: 'APP/HIDE_GDPR',
});

export type ChooseRedirectCallback = {|
  type: 'APP/CHOOSE_REDIRECT_CALLBACK',
  payload: Function,
|};
export const chooseRedirectCallback = (redirectCallback: Function): ChooseRedirectCallback => ({
  type: 'APP/CHOOSE_REDIRECT_CALLBACK',
  payload: redirectCallback,
});
