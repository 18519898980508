// @flow
import type {ProductOffering} from 'data/product/types';
import withEmpty from 'hoc/withEmpty';
import withLoader from 'hoc/withLoader';
import {values} from 'ramda';
import React from 'react';
import {type HOC, compose} from 'recompose';

import ProductTile from './ProductTile';
import {ProductListWrap} from './styled';

/**
 * - bundles and products inline togethor
 * - pricing display for bundles (aggregate of components)
 * - displaying of bundles different affiliates (product filter for bundles)
 */
const ProductList = ({productOfferings, maxTilesPerRow}) => {
  const offerings = mergeDuplicateOfferings(productOfferings);
  return (
    <ProductListWrap>
      {offerings.map(productOffering => (
        <ProductTile
          key={productOffering.productId + '-' + productOffering.affiliateId}
          productOffering={productOffering}
          maxTilesPerRow={maxTilesPerRow}
        />
      ))}
    </ProductListWrap>
  );
};

/**
 * Merges offerings with the same product and affiliate by combining their
 * pricing details.
 *
 * NOTE: This is somewhat of a hack because the merged offering's pricing
 * details array will be specifically constructed to conform to what
 * `ProductTile` expects to display the price range. It will not conform to the
 * original semantic structure of the pricing details array (that which is
 * reflected by the `Price` component which is not used here)
 */
function mergeDuplicateOfferings(offerings: ProductOffering[]) {
  // Merge offerings for the same product and affiliate by combining their
  // pricing details
  const offeringMap: {[productIdAndAffiliateId: string]: ProductOffering} = {};
  for (const offering of offerings) {
    const key = offering.productId + '-' + offering.affiliateId;
    const existing = offeringMap[key];
    if (!existing) {
      offeringMap[key] = offering;
    } else {
      // Merge the two offerings by merging the prices.
      // Assume that the other properties are the same.
      const mergedPrices = []
        .concat(existing.pricingDetails.prices)
        .concat(offering.pricingDetails.prices);
      mergedPrices.sort().reverse();

      const merged = {
        ...existing,
        pricingDetails: {
          prices: mergedPrices,
        },
      };
      offeringMap[key] = merged;
    }
  }

  return values(offeringMap);
}

type Outter = {|
  loading?: boolean,
  productOfferings: ProductOffering[],
  maxTilesPerRow?: number,
|};

const enhancer: HOC<*, Outter> = compose(
  withLoader(props => !!props.loading),
  withEmpty(props => !props.productOfferings || !props.productOfferings.length)
);

export default enhancer(ProductList);
