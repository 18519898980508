// @flow
import ModalContent from 'modals/_Content';
import ModalHeader from 'modals/_Header';
import * as React from 'react';

type Props = {|
  title: string,
  back: Function,
  clear: Function,
  children: React.Node,
|};

const sanitizeString = (s: string) =>
  s
    .split('_')
    .map(x => `${x[0].toUpperCase()}${x.slice(1)}`)
    .join(' ');

const Submodal = ({title, back, clear, children}: Props) => (
  <React.Fragment>
    <ModalHeader back={back} title={sanitizeString(title)} clear={clear} />
    <ModalContent>{children}</ModalContent>
  </React.Fragment>
);

export default Submodal;
