// @flow
import type {GraphqlError} from 'common/graphql/types';
import BagAffiliateGroup from 'components/Bag/BagAffiliateGroup';
import {
  LineItemName,
  LineItems,
  StyledBag,
  SummarySection,
  SummaryTitle,
} from 'components/Bag/styled';
import {clearBasket} from 'data/basket/actions';
import {selectBasketItems} from 'data/basket/selectors';
import type {PendingReservation} from 'data/basket/types';
import {notificationError} from 'data/notifications/actions';
import {estimateGroupingForReservationQuery} from 'data/orders/graphql/queries';
import type {ReservationPricingForAffiliate} from 'data/reservations/types';
import {formatMoney} from 'data/units/money/formatters';
import withConnect from 'hoc/withConnect';
import withQuery from 'hoc/withQuery';
import withUser from 'hoc/withUser';
import {path} from 'ramda';
import React from 'react';
// $ReactHooks
import {useEffect} from 'react';
import type {HOC} from 'recompose';
import {compose} from 'recompose';

type Props = {
  data: ReservationPricingForAffiliate[],
  canDelete?: boolean,
  showCurrencyCode?: boolean,
  excludeSummary?: boolean,
  error: ?GraphqlError,
  items: PendingReservation[],
  clearBasket: () => void,
  notificationError: (text: string) => void,
};

export const BagContext = React.createContext({
  canDelete: false,
  showCurrencyCode: false,
});

const Bag = ({
  data,
  canDelete,
  error,
  items,
  clearBasket,
  notificationError,
  showCurrencyCode,
  excludeSummary = false,
}: Props) => {
  const hasError = !!error;
  //If there's an error then clear the user's cart to prevent unrecoverable cart state
  useEffect(() => {
    if (hasError && items && items.length) {
      clearBasket();
      notificationError(
        'The items in your cart are no longer available. Please add your items again.'
      );
    }
  }, [hasError, items, clearBasket, notificationError]);

  if (!data) {
    return null;
  }

  //TODO: Fix Me.
  //Very hacky and incorrect if there are multiple currencies, but no alternative is currently available
  const currency =
    path([0, 'deliveryReservations', 0, 'currency'], data) ||
    path([0, 'inStoreReservations', 0, 'currency'], data);
  const total = data.reduce((sum, {total}) => sum + total, 0);

  return (
    <StyledBag>
      <BagContext.Provider value={{canDelete, showCurrencyCode}}>
        {data.map(reservationPricingForAffiliate => (
          <BagAffiliateGroup
            key={reservationPricingForAffiliate.affiliate.id}
            reservationPricingForAffiliate={reservationPricingForAffiliate}
          />
        ))}
      </BagContext.Provider>

      {!excludeSummary && (
        <SummarySection>
          <SummaryTitle>Summary</SummaryTitle>
          <LineItems canDelete={canDelete}>
            <LineItemName>Subtotal</LineItemName>
            {/* $ExpectError flow is having a fit over currency but formatMoney allows null currency prop */}
            <span>{formatMoney(total, true, currency)}</span>
            <LineItemName>Total</LineItemName>
            {/* $ExpectError flow is having a fit over currency but formatMoney allows null currency prop*/}
            <span>{formatMoney(total, true, currency)}</span>
          </LineItems>
        </SummarySection>
      )}
    </StyledBag>
  );
};

const mapStateToProps = state => ({
  items: selectBasketItems(state),
});

const mapDispatchToProps = {
  clearBasket,
  notificationError,
};

type Outter = {
  canDelete?: boolean,
};

const enhancer: HOC<*, Outter> = compose(
  withUser(),
  withConnect(mapStateToProps, mapDispatchToProps),
  withQuery(estimateGroupingForReservationQuery, {
    variables: props => {
      const reservations = props.items.map((item: PendingReservation) => ({
        start: item.start,
        end: item.end,
        affiliateId: item.affiliate.affiliateId,
        productVariantId: item.productVariant.id,
        accessories: item.accessories.map(a => ({name: a.name})),
        bundleComponentId: item.bundleComponentId,
        fulfillmentType: item.fulfillmentType,
      }));
      return {reservations};
    },
    noEmpty: true,
    noNotFound: true,
    config: {
      skip: props => !(props.items && props.items.length > 0),
    },
  })
);

export default enhancer(Bag);
