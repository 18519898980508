// @flow
import {selectCategories, selectGeolocation} from 'data/app/selectors';
import type {EnumValue} from 'data/enums/types';
import {filterableParametersQuery} from 'data/search/graphql/queries/params';
import {selectSearchParams} from 'data/search/selectors';
import withConnect from 'hoc/withConnect';
import withQuery from 'hoc/withQuery';
import {prop, sortBy} from 'ramda';
import React from 'react';
import {type HOC, compose} from 'recompose';
import Multiselect from 'searchFilters/Shared/Multiselect';

const EnumFilter = ({data, onChange, value, paramKey}) => {
  const paramData = data && data[paramKey];
  if (!paramData || paramData.type !== 'enum_value') return null;

  // Evaluate if enums have an order
  const allValuesAreOrdered = paramData.enum.values.every(enumValue => enumValue.order !== null);
  const orderedEnumValues = allValuesAreOrdered
    ? sortBy(prop('order'), paramData.enum.values)
    : sortBy(prop('name'), paramData.enum.values);

  return <Multiselect options={orderedEnumValues} value={value} onChange={onChange} />;
};

const mapStateToProps = state => ({
  params: selectSearchParams(state),
  categories: selectCategories(state),
  geolocation: selectGeolocation(state),
});

type Outter = {|
  onChange: (?(EnumValue[])) => mixed,
  value: ?(EnumValue[]),
  paramKey: string,
|};

const enhancer: HOC<*, Outter> = compose(
  withConnect(mapStateToProps),
  withQuery(filterableParametersQuery, {
    variables: props => ({
      categories: props.categories && props.categories.map(category => category.name),
      locationIds: props.geolocation && props.geolocation.map(geo => geo.id),
      affiliateIds: props.params.affiliate ? [props.params.affiliate] : undefined,
    }),
    noLoader: true,
    noEmpty: true,
  })
);

export default enhancer(EnumFilter);
