// @flow
import styled, {css} from 'styled-components';

export const Half = styled.div`
  width: 50%;

  ${p =>
    p.white &&
    css`
      position: relative;

      &::after {
        position: fixed;
        left: 0;
        top: -20rem;
        height: 200vh;
        width: 100vw;
        background: #fff;
        content: '';
        z-index: -1;
      }
    `}
`;
