// @flow
import ArrowIcon from 'assets/icons/ArrowIcon';
import {H3} from 'componentsStyled/Typography';
import {Text} from 'componentsStyled/Typography/Texts';
import withOnMount from 'hoc/withOnMount';
import * as React from 'react';
import Collapse from 'react-collapse';
import {type HOC, compose, withStateHandlers} from 'recompose';

import {Expander, SubGroupWrap} from './styled';

// Like Collapsible Panel, however it is styled to better suit its application
const CollapsibleParagraph = ({
  group,
  toggleCollapse,
  isCollapsed,
  children,
  maxHeight,
  showExpandText = true,
}) => {
  const rotationCondition = isCollapsed ? 'up' : undefined;
  const style = maxHeight ? {overflowY: 'scroll', maxHeight} : null;

  return (
    <React.Fragment>
      <SubGroupWrap onClick={toggleCollapse} isOpened={!isCollapsed}>
        <H3 bold black>
          {group}
        </H3>
        <Expander>
          {showExpandText && <Text>{isCollapsed ? 'Collapse' : 'Expand'}</Text>}
          <ArrowIcon marginLeft floatRight rotation={rotationCondition} />
        </Expander>
      </SubGroupWrap>
      <Collapse style={style} isOpened={isCollapsed}>
        {children}
      </Collapse>
    </React.Fragment>
  );
};

type Outter = {|
  children: React.Node,
  group: string,
  center?: boolean,
  open?: boolean,
  maxHeight?: string,
  showExpandText?: boolean,
|};

const enhancer: HOC<*, Outter> = compose(
  withStateHandlers(
    {isCollapsed: false},
    {
      toggleCollapse: props => () => ({isCollapsed: !props.isCollapsed}),
    }
  ),
  withOnMount(props => {
    if (props.open) props.toggleCollapse();
  })
);

export default enhancer(CollapsibleParagraph);
