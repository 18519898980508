// @flow
import {InMemoryCache, IntrospectionFragmentMatcher} from 'apollo-cache-inmemory';

import introspectionQueryResultData from './introspectionQueryResultData';

// needed for dynamic params in products
// source: https://www.apollographql.com/docs/react/recipes/fragment-matching.html
const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});

const cache = new InMemoryCache({
  fragmentMatcher,
});

export default cache;
