// @flow
import Button from 'components/Button';
import {selectActivity, selectCategories, selectGeolocation} from 'data/app/selectors';
import {clear, filter} from 'data/search/actions';
import {selectSearchParams} from 'data/search/selectors';
import withConnect from 'hoc/withConnect';
import ModalBody from 'modals/_Body';
import ModalContent from 'modals/_Content';
import ModalControls from 'modals/_Controls';
import ModalHeader from 'modals/_Header';
import * as React from 'react';
import {type HOC, compose, withHandlers, withStateHandlers} from 'recompose';

import EnumFilter from './ActivityFilters/EnumFilter';
import EnumFilterSubmodal from './EnumFilterSubmodal';

const SetMoreFiltersModal = ({
  close,
  localValue,
  setLocalValue,
  propagateLocalValue,
  handleClear,
  enumDetail,
  editEnum,
  closeEnum,
  categories,
  geolocation,
  multipleBrands,
}) => {
  if (enumDetail)
    return (
      <ModalBody close={close}>
        <EnumFilterSubmodal
          paramKey={enumDetail}
          value={localValue}
          onChange={setLocalValue}
          categories={categories}
          geolocation={geolocation}
          back={closeEnum}
        />
      </ModalBody>
    );

  return (
    <ModalBody close={close}>
      <ModalHeader close={close} title="Filters" clear={handleClear} />
      <ModalContent>
        {multipleBrands && (
          <EnumFilter title="Brand" value={localValue.brand} onClick={() => editEnum('brand')} />
        )}
        <EnumFilter
          title="Product"
          value={localValue.product}
          onClick={() => editEnum('product')}
        />
        <ModalControls>
          <Button fullwidth onClick={propagateLocalValue}>
            Show items
          </Button>
        </ModalControls>
      </ModalContent>
    </ModalBody>
  );
};

const mapStateToProps = state => ({
  params: selectSearchParams(state),
  geolocation: selectGeolocation(state),
  activity: selectActivity(state),
  categories: selectCategories(state),
});

const mapDispatchToProps = {
  filter,
  clear,
};

type Outter = {|
  close: Function,
  multipleBrands: boolean,
|};

const enhancer: HOC<*, Outter> = compose(
  withConnect(mapStateToProps, mapDispatchToProps),
  withStateHandlers(
    props => ({
      localValue: props.params,
      enumDetail: undefined,
    }),
    {
      setLocalValue:
        ({localValue}) =>
        v => ({
          localValue: {
            ...localValue,
            ...v,
          },
        }),
      clearLocalValue: () => v => ({localValue: {}}),
      editEnum: () => (x: string) => ({enumDetail: x}),
      closeEnum: () => () => ({enumDetail: undefined}),
    }
  ),
  withHandlers({
    propagateLocalValue: props => () => {
      props.filter(props.localValue);
      props.close();
    },
    handleClear: props => () => {
      props.clear();
      props.close();
    },
  })
);

export default enhancer(SetMoreFiltersModal);
