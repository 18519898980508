// @flow
import Loader from 'components/Loader';
import type {Category, Location} from 'data/app/types';
import {filterableParametersQuery} from 'data/search/graphql/queries/params';
import type {SearchParams} from 'data/search/types';
import withQuery from 'hoc/withQuery';
import {prop, sortBy} from 'ramda';
import React from 'react';
import {type HOC} from 'recompose';
import Multiselect from 'searchFilters/Shared/Multiselect';

import Submodal from './Submodal';

const EnumFilterSubmodal = ({data, paramKey, onChange, value, back, loading}) => {
  const paramData = data && data[paramKey];
  const options =
    paramData && paramData.type === 'enum_value' && sortBy(prop('name'), paramData.enum.values);

  return (
    <Submodal back={back} title={paramKey} clear={() => onChange({[paramKey]: []})}>
      {loading ? (
        <Loader />
      ) : (
        options && (
          <Multiselect
            options={options}
            value={value[paramKey] || []}
            onChange={x => onChange({[paramKey]: x})}
          />
        )
      )}
    </Submodal>
  );
};

type Outter = {|
  paramKey: string,
  onChange: SearchParams => mixed,
  value: SearchParams,
  geolocation: ?(Location[]),
  categories: ?(Category[]),
  back: Function,
|};

const enhancer: HOC<*, Outter> = withQuery(filterableParametersQuery, {
  variables: props => ({
    categories: props.categories && props.categories.map(category => category.name),
    locationIds: props.geolocation && props.geolocation.map(geo => geo.id),
    affiliateIds: props.value.affiliate ? [props.value.affiliate] : undefined,
  }),
  noLoader: true,
  noEmpty: true,
});

export default enhancer(EnumFilterSubmodal);
