// @flow
import type {Query} from 'common/graphql/types';
import {affiliateFragmentMinimal} from 'data/affiliate/fragments';
import type {AffiliateMinimal} from 'data/affiliate/types';
import {paginationFragment} from 'data/app/graphql/fragments';
import type {CategoryName} from 'data/app/types';
import type {Offering} from 'data/bundle/types';
import type {ID} from 'data/enums/types';
import gql from 'graphql-tag';
import {path} from 'ramda';

import {productOfferingFragment} from '../product/graphql/fragments';
import {bundleOfferingFragment} from './fragments';
import type {BundleComponent} from './types';

export const listOfferings: Query<
  Array<Offering>,
  {
    productId?: ID,
    manufacturerIds?: Array<ID>,
    locationIds?: Array<ID>,
    categories?: Array<CategoryName>,
  }
> = {
  gql: gql`
    query listOfferings(
      $productIds: [Int!]
      $locationIds: [Int!]
      $manufacturerIds: [Int!]
      $categories: [String]
      $limit: Int
      $offset: Int
    ) {
      bundle {
        listCompositeBundleProductOfferings(
          filter: {
            productIds: $productIds
            locationIds: $locationIds
            manufacturerIds: $manufacturerIds
            categories: $categories
            limit: $limit
            offset: $offset
          }
        ) {
          pageInfo {
            ...paginationFragment
          }
          data {
            ... on ProductOffering {
              ...productOfferingFragment
            }
            ... on BundleOffering {
              ...bundleOfferingFragment
            }
          }
        }
      }
    }
    ${paginationFragment}
    ${productOfferingFragment}
    ${bundleOfferingFragment}
  `,
  selector: ['bundle', 'listCompositeBundleProductOfferings', 'data'],
  pagination: ['bundle', 'listCompositeBundleProductOfferings'],
};

export const listAffiliateBundleComponentsQuery: Query<
  {
    affiliate: AffiliateMinimal,
    bundleComponents: BundleComponent[],
  },
  {
    affiliateId: ID,
    bundleId: ID,
  }
> = {
  gql: gql`
    query listAffiliateBundleComponentsQuery($affiliateId: Int!, $bundleId: Int!) {
      affiliate {
        get(id: $affiliateId) {
          ...affiliateFragmentMinimal
        }
      }
      bundle {
        listAffiliateBundleComponents(filter: {affiliateId: $affiliateId, bundleId: $bundleId}) {
          id
          productVariant {
            id
            name
            product {
              id
              name
              images {
                alt
                order
                sizes {
                  url
                  width
                  height
                }
              }
              categories {
                categoryId
                name
              }
            }
            affiliates {
              affiliateId
              name
              availability {
                startDate: start
                endDate: end
              }
              location {
                country {
                  currency
                }
              }
            }
          }
          pricingDetails {
            prices
          }
          bundle {
            name
            description
            category {
              name
            }
            images {
              alt
              order
              sizes {
                url
                width
                height
              }
            }
          }
        }
      }
    }
    ${affiliateFragmentMinimal}
  `,
  transform: data => ({
    // $Ramda
    affiliate: path(['affiliate', 'get'], data),
    // $Ramda
    bundleComponents: path(['bundle', 'listAffiliateBundleComponents'], data),
  }),
};
