// @flow strict-local
import getErrorMessage from 'common/graphql/getErrorMessage';
import Bag from 'components/Bag';
import Button from 'components/Button';
import DeliverySummary from 'components/DeliverySummary';
import {Space} from 'componentsStyled/Layout/Spacers';
import {PageTitle} from 'componentsStyled/Typography/Titles';
import {selectAppConfig} from 'data/app/selectors';
import {addToBasket} from 'data/basket/actions';
import {clearBasket} from 'data/basket/actions';
import {getUniqueCurrencies} from 'data/basket/helpers';
import {selectBasketItems} from 'data/basket/selectors';
import type {PendingReservation} from 'data/basket/types';
import {closeModal, openModal} from 'data/modals/actions';
import {createOrderFromReservationsMutation} from 'data/orders/graphql/mutations';
import {selectShippingAddress} from 'data/reservations/selectors';
import type {ReservationInput} from 'data/reservations/types';
import urls from 'data/router/urls';
import {clear} from 'data/search/actions';
import {shippingAddressToDeliveryDestination} from 'data/shipping/helpers';
import {getPricePerDayArray} from 'data/units/money/helpers';
import {getPaymentMethodQuery} from 'data/user/graphql/queries';
import withConnect from 'hoc/withConnect';
import withMutation from 'hoc/withMutation';
import withQuery from 'hoc/withQuery';
import withRouter from 'hoc/withRouter';
import withSubmit from 'hoc/withSubmit';
import withUser from 'hoc/withUser';
import CreditCard from 'modals/CreditCard';
import ReservationSuccess from 'modals/ReservationSuccess';
import {equals, groupWith, sum} from 'ramda';
// $ReactHooks
import React, {useEffect} from 'react';
import {type HOC, compose, withHandlers, withProps, withState} from 'recompose';

import {BasketCheckoutFormWrap, CardIcon, CardSection, CreditCardInfo} from './styled';

const BasketCheckoutForm = ({
  data: paymentMethodData,
  items,
  submit,
  loading,
  openCardModal,
  deliveryAddress,
  history,
  deliveryDestination,
}) => {
  const hasDeliveryReservations = items.some(item => item.fulfillmentType === 'DELIVERY');
  const needAddressDetails = hasDeliveryReservations && !deliveryAddress;
  const haseMultipleCurrencies = getUniqueCurrencies(items).length > 1;

  useEffect(() => {
    // Redirect user to cart page if they have items across multiple currencies in their cart
    if (haseMultipleCurrencies) {
      return history.push(urls.shoppingBasket);
    }
    // Redirect user to delivery address page if they have a delivery reservation and no address
    if (needAddressDetails) {
      return history.push(urls.deliveryAddress);
    }
  }, [needAddressDetails, history, haseMultipleCurrencies]);

  return (
    <BasketCheckoutFormWrap narrow data-cy={'basket-checkout-form'}>
      <PageTitle>Checkout</PageTitle>
      <Bag />
      {hasDeliveryReservations && <DeliverySummary deliveryDestination={deliveryDestination} />}
      <CardSection>
        {paymentMethodData && (
          <CreditCardInfo success>
            <CardIcon>✓</CardIcon> {paymentMethodData.brand} ending in {paymentMethodData.last4}
          </CreditCardInfo>
        )}
        <Button secondary fullwidth onClick={openCardModal}>
          {paymentMethodData ? 'Update Card Details' : 'Add Card Details'}
        </Button>
      </CardSection>
      <Space />
      <Button
        fullwidth
        onClick={submit}
        disabled={loading || !paymentMethodData}
        loading={loading}
        data-cy={'confirm-and-pay'}
      >
        Confirm and Pay
      </Button>
    </BasketCheckoutFormWrap>
  );
};

const mapStateToProps = state => ({
  appConfig: selectAppConfig(state),
  items: selectBasketItems(state),
  deliveryAddress: selectShippingAddress(state),
});

type Outter = {||};

const mapDispatchToProps = {
  openModal,
  closeModal,
  clear,
  addToBasket,
  clearBasket,
};

const enhancer: HOC<*, Outter> = compose(
  withRouter,
  withUser(),
  withConnect(mapStateToProps, mapDispatchToProps),
  withQuery(getPaymentMethodQuery, {
    noEmpty: true,
    config: {
      skip: props => !props.user.customer,
    },
  }),
  withMutation(createOrderFromReservationsMutation),
  withProps(({dates, variantAffiliate, deliveryAddress}) => {
    const prices = getPricePerDayArray(dates, variantAffiliate);
    return {
      deliveryDestination: deliveryAddress
        ? shippingAddressToDeliveryDestination(deliveryAddress)
        : null,
      priceGroups: groupWith(equals, prices),
      total: sum(prices),
    };
  }),
  withSubmit({
    submit:
      ({deliveryDestination, items, submitMutation, user}) =>
      () => {
        const reservations: ReservationInput[] = items.map((item: PendingReservation) => ({
          start: item.start,
          end: item.end,
          affiliateId: item.affiliate.affiliateId,
          productVariantId: item.productVariant.id,
          paymentMethod: item.paymentMethod,
          accessories: item.accessories.map(a => ({name: a.name})),
          profileId: user.profiles[0].id,
          bundleComponentId: item.bundleComponentId,
          fulfillmentType: item.fulfillmentType,
          deliveryDestination:
            item.fulfillmentType === 'DELIVERY' && deliveryDestination
              ? deliveryDestination
              : undefined,
        }));
        return submitMutation({reservations});
      },
    redirect: () => urls.reservations,
    redirectState: () => ({initialTabIndex: 0}), //Sets the 'Upcoming' tab as focused
    onSuccess: props => res => {
      props.clearBasket();
      props.openModal(ReservationSuccess, {});
    },
    onError: props => error => {
      getErrorMessage(error);
    },
  }),
  withState('acceptedTermsAndConditions', 'setAcceptedTermsAndConditions', false),
  withHandlers({
    toggleTermsAndConditions:
      ({setAcceptedTermsAndConditions}) =>
      () =>
        setAcceptedTermsAndConditions(isAccepted => !isAccepted),
    openCardModal: props => () => {
      const hasCardData = !!props.data;
      props.openModal(CreditCard, {
        type: hasCardData ? 'edit' : 'add',
        allowSkip: false,
        data: {...props.data, user: props.user},
      });
    },
  })
);

export default enhancer(BasketCheckoutForm);
