// @flow strict-local
import Button from 'components/Button';
import ProductPreview from 'components/ProductPreview';
import ReservationPricing from 'components/ReservationPricing';
import {VPaddedFullWidthContainer} from 'componentsStyled/Layout/Containers';
import {Space} from 'componentsStyled/Layout/Spacers';
import {Wrap, WrapGrouper} from 'componentsStyled/Layout/Wrap';
import {Text} from 'componentsStyled/Typography/Texts';
import {selectAppConfig} from 'data/app/selectors';
import {addToBasket} from 'data/basket/actions';
import type {PendingReservation} from 'data/basket/types';
import type {FulfillmentType} from 'data/bookings/types';
import {openModal} from 'data/modals/actions';
import type {ProductVariant, ProductVariantAvailableAffiliate} from 'data/product/types';
import {pickAccessories} from 'data/reservations/actions';
import {
  selectAccessories,
  selectDates,
  selectOriginalReservation,
} from 'data/reservations/selectors';
import urls from 'data/router/urls';
import {clear} from 'data/search/actions';
import {getPricePerDayArray} from 'data/units/money/helpers';
import Alert from 'forms/Alert';
import withConnect from 'hoc/withConnect';
import withRouter from 'hoc/withRouter';
import withUser from 'hoc/withUser';
import {equals, groupWith, sum} from 'ramda';
import * as React from 'react';
import {Redirect} from 'react-router-dom';
import {type HOC, compose, withHandlers, withProps} from 'recompose';

import Accessories from './Accessories';
import Dates from './Dates';

const CartForm = ({
  appConfig,
  productVariant,
  dates,
  variantAffiliate,
  handleContinueReservation,
  handleAddToBagAndContinueShopping,
  handleAddToBagAndCheckout,
  loading,
  user,
  originalReservation,
  pickAccessories,
  accessories, // The list of chosen accessories by the user
  openModal,
  error,
  fulfillmentType,
}) => {
  if (!dates) {
    return <Redirect to={urls.products} />;
  }

  const currency = variantAffiliate.location.country.currency;

  const pricingInput = {
    productVariantId: productVariant.id,
    affiliateId: variantAffiliate.affiliateId,
    start: dates.startDate,
    end: dates.endDate,
    accessories: accessories && accessories.map(a => ({name: a.name})),
    ignoreTax: true,
  };

  const availability = {
    product: productVariant,
    user: user,
    selectedDates: {
      startDate: dates.startDate,
      endDate: dates.endDate,
    },
    affiliate: variantAffiliate.name,
  };

  // TODO Handle multiple categories
  const category = productVariant.product.categories[0];

  return (
    <VPaddedFullWidthContainer narrow data-cy={'cart-form'}>
      <WrapGrouper>
        <Text bold black>
          Booking details
        </Text>
        <Wrap>
          <Dates
            fulfillmentType={fulfillmentType}
            dates={dates}
            originalReservation={originalReservation}
          />
        </Wrap>
        <Wrap>
          <ProductPreview productVariant={productVariant} affiliateName={variantAffiliate.name} />
        </Wrap>
        <Accessories
          handleAccessoriesSelection={pickAccessories}
          pricingInput={pricingInput}
          currency={currency}
          originalReservation={originalReservation}
        />
      </WrapGrouper>
      <WrapGrouper>
        <Text bold black>
          Total
        </Text>
        <Wrap>
          <ReservationPricing
            input={pricingInput}
            originalReservation={originalReservation}
            availability={availability}
            categoryName={category.name}
            user={user}
          />
          {fulfillmentType === 'DELIVERY' && <p>Delivery total will be shown at checkout.</p>}
        </Wrap>
      </WrapGrouper>
      <Alert>{error}</Alert>
      <Button fullwidth onClick={handleAddToBagAndCheckout} data-cy={'cart-checkout'}>
        Add to cart and checkout
      </Button>
      <Space />
      <Button fullwidth onClick={handleAddToBagAndContinueShopping} secondary>
        Add to cart and continue shopping
      </Button>
    </VPaddedFullWidthContainer>
  );
};

const mapStateToProps = state => ({
  appConfig: selectAppConfig(state),
  dates: selectDates(state),
  originalReservation: selectOriginalReservation(state),
  accessories: selectAccessories(state),
});

type Outter = {|
  productVariant: ProductVariant,
  variantAffiliate: ProductVariantAvailableAffiliate,
  fulfillmentType: FulfillmentType,
|};

const mapDispatchToProps = {
  openModal,
  pickAccessories,
  clear,
  addToBasket,
};

const createPendingReservation = (props): PendingReservation => {
  // Guarding against possible `null` type to prevent flow type warnings - null shouldn't ever happen
  if (!props.dates) {
    throw new Error('There are no dates selected');
  }
  const currentTime = new Date();
  return {
    start: props.dates.startDate,
    end: props.dates.endDate,
    affiliate: props.variantAffiliate,
    productVariant: props.productVariant,
    paymentMethod: 'card',
    accessories: props.accessories.map(a => ({name: a.name})),
    timeCreated: currentTime.toISOString(),
    fulfillmentType: props.fulfillmentType,
  };
};

const enhancer: HOC<*, Outter> = compose(
  withUser(),
  withConnect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withProps(({dates, variantAffiliate}) => {
    const prices = getPricePerDayArray(dates, variantAffiliate);
    return {
      priceGroups: groupWith(equals, prices),
      total: sum(prices),
    };
  }),
  withHandlers({
    handleAddToBagAndContinueShopping: props => () => {
      props.addToBasket(createPendingReservation(props));
      const goToProductPage = () => props.history.push(urls.products);
      return goToProductPage();
    },
    handleAddToBagAndCheckout: props => () => {
      props.addToBasket(createPendingReservation(props));
      const goToShoppingBasket = () => props.history.push(urls.shoppingBasket);
      return goToShoppingBasket();
    },
  })
);

export default enhancer(CartForm);
