// @flow
import gql from 'graphql-tag';

export const bundleOfferingFragment = gql`
  fragment bundleOfferingFragment on BundleOffering {
    bundleId
    name
    category {
      categoryId
      name
    }
    components {
      pricingDetails {
        prices
      }
      productVariant {
        product {
          id
        }
      }
    }
    images {
      alt
      order
      sizes {
        url
        width
        height
      }
    }
    affiliateId
    affiliateName
    currency
  }
`;
