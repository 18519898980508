// @flow
import {chooseCategories, chooseGeolocation} from 'data/app/actions';
import type {Category, Location} from 'data/app/types';
import {openModal} from 'data/modals/actions';
import {filter} from 'data/search/actions';
import type {SearchParams} from 'data/search/types';
import withConnect from 'hoc/withConnect';
import SetMoreFilters from 'modals/SetActivityFilters';
import {omit} from 'ramda';
import React from 'react';
import {type HOC, compose, withHandlers} from 'recompose';
import FilterInput from 'searchFilters/Shared/FilterInput';

import AllActivityFilters from './AllActivityFilters';

const MainFilter = ({
  geolocation,
  categories,
  filter,
  chooseGeolocation,
  chooseCategories,
  params,
  handleChangeProductTypeFilters,
}) => {
  return (
    <React.Fragment>
      <AllActivityFilters
        geolocation={geolocation}
        categories={categories}
        chooseGeolocation={chooseGeolocation}
        chooseCategories={chooseCategories}
        filter={filter}
        params={params}
      />
      <FilterInput
        onClick={handleChangeProductTypeFilters}
        placeholder="Filters"
        value={omit(['affiliate'], params)}
        formatter={() => 'Filters'}
      />
    </React.Fragment>
  );
};

const mapDispatchToProps = {
  openModal,
};

type Outter = {|
  geolocation: ?(Location[]),
  categories: ?(Category[]),
  chooseGeolocation?: typeof chooseGeolocation,
  chooseCategories: typeof chooseCategories,
  params: SearchParams,
  filter: typeof filter,
  multipleBrands: boolean,
|};

const enhancer: HOC<*, Outter> = compose(
  withConnect(() => ({}), mapDispatchToProps),
  withHandlers({
    handleChangeProductTypeFilters: props => () =>
      props.openModal(SetMoreFilters, {multipleBrands: props.multipleBrands}),
  })
);

export default enhancer(MainFilter);
