// @flow
import AffiliateName from 'components/AffiliateName';
import ProductDimension from 'components/ProductDimension';
import VariantVariablesDisplay from 'components/VariantVariablesDisplay';
import {Text} from 'componentsStyled/Typography/Texts';
import {SubTitle} from 'componentsStyled/Typography/Titles';
import {minHeightImg, selectIndexImage} from 'data/images/helpers';
import type {ProductVariant} from 'data/product/types';
import React from 'react';

import {Content, ImageWrap, ProductPreviewWrap} from './styled';

type Props = {|
  productVariant: ProductVariant,
  affiliateName: string,
|};

const ProductPreview = ({productVariant, affiliateName}: Props) => {
  const indexImage = selectIndexImage(productVariant.product.images);
  const imageSize = minHeightImg(indexImage, 100);

  return (
    <ProductPreviewWrap>
      <ImageWrap>
        <img src={imageSize.url} alt={indexImage.alt} draggable="false" />
      </ImageWrap>
      <Content>
        <Text>{productVariant.product.manufacturer.name}</Text>
        <SubTitle>{productVariant.product.name}</SubTitle>
        {
          // Product preview does not have/show variant name for upcoming reservations
          productVariant.product.variantVariables && productVariant.name ? (
            <VariantVariablesDisplay
              variantVariableLabels={productVariant.product.variantVariables}
              variantItemNames={productVariant.name}
            />
          ) : (
            // ProductDimension returns null if no productVariant.name
            <ProductDimension productVariant={productVariant} compact />
          )
        }
        <AffiliateName name={affiliateName} />
      </Content>
    </ProductPreviewWrap>
  );
};

export default ProductPreview;
