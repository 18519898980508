// @flow
import {lighten} from 'global/theme';
import styled, {css} from 'styled-components';

export const Container = styled.div`
  position: relative;
`;

const horizontalPadding = '1.9rem';

export const StyledSelect = styled.select`
  height: 5rem;
  border: solid 0.1rem ${props => props.theme.color.lightGray};
  color: ${props => props.theme.color.secondaryText};
  border-radius: ${props => props.theme.borderRadiuses.input};
  background: ${props => props.theme.color.white};
  padding: 0 ${horizontalPadding};
  font-size: 1.6rem;
  font-weight: ${props => props.theme.fontWeights.book};
  width: 100%;
  outline: none;
  opacity: 1;
  -webkit-appearance: none;
  -moz-appearance: none;
  position: relative;

  ${p =>
    p.empty &&
    css`
      color: ${props => lighten(props.theme.color.secondaryText, 0.5)};
    `}
  &::-ms-expand {
    display: none;
  }

  &::-moz-focus-inner,
  &:-moz-focusring {
    color: transparent !important;
    text-shadow: 0 0 0 #000 !important;
    background-image: none !important;
  }

  &:focus {
    border-color: ${props => props.theme.color.primaryAccent};
    z-index: ${props => props.theme.zIndices.minimal};
  }
`;

export const Option = styled.option`
  color: ${props => props.theme.color.secondaryText};
`;

export const EmptyOption = styled.option`
  color: ${props => lighten(props.theme.color.secondaryText, 0.2)};
`;

export const Arrow = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: ${horizontalPadding};
  z-index: 1;
  pointer-events: none;
`;
