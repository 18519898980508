// @flow
import {Text} from 'componentsStyled/Typography/Texts';
import {SubTitle} from 'componentsStyled/Typography/Titles';
import {selectAppConfig} from 'data/app/selectors';
import {minHeightImg, selectIndexImage} from 'data/images/helpers';
import type {ProductOffering} from 'data/product/types';
import urls from 'data/router/urls';
import {formatPricing} from 'data/units/money/formatters';
import withConnect from 'hoc/withConnect';
import withRouter from 'hoc/withRouter';
import React from 'react';
import {type HOC, compose, withHandlers} from 'recompose';

import type {AppConfig} from '../../../data/app/types';
import {Body, ImageWrap, ProductTileWrap} from './styled';

export const ProductTileInner = ({
  productOffering,
  maxTilesPerRow,
  handleClick,
  appConfig,
}: Props) => {
  const showMinimalProductTileInfo = appConfig && appConfig.showMinimalProductTileInfo;
  const indexImage = selectIndexImage(productOffering.images);
  const imageSize = minHeightImg(indexImage, 250);
  const currency =
    appConfig && appConfig.showMinimalProductTileInfo ? undefined : productOffering.currency;

  return (
    <ProductTileWrap
      onClick={handleClick}
      minimal={showMinimalProductTileInfo}
      maxTilesPerRow={maxTilesPerRow}
      data-cy={'product-tile'}
    >
      <ImageWrap>
        <img src={imageSize.url} alt={indexImage.alt} draggable="false" />
      </ImageWrap>
      <Body>
        {!showMinimalProductTileInfo && <Text small>{productOffering.manufacturer.name}</Text>}
        <SubTitle data-cy={'product-name'}>{productOffering.name}</SubTitle>
        <Text small>
          <Text small inline black book>
            {formatPricing(productOffering.pricingDetails.prices, currency)}
          </Text>{' '}
          per day
        </Text>
        {!showMinimalProductTileInfo && <Text small>{productOffering.affiliateName}</Text>}
      </Body>
    </ProductTileWrap>
  );
};

const mapStateToProps = state => ({
  appConfig: selectAppConfig(state),
});

type Outter = {|
  productOffering: ProductOffering,
  maxTilesPerRow?: number,
|};

type Props = Outter & {|
  appConfig: AppConfig,
  handleClick: Function,
|};

const enhancer: HOC<*, Outter> = compose(
  withRouter,
  withConnect(mapStateToProps),
  withHandlers({
    handleClick: props => () => {
      props.history.push(
        urls.product(props.productOffering.productId, props.productOffering.affiliateId)
      );
    },
  })
);

export default enhancer(ProductTileInner);
