// @flow
import type {EnumValue} from 'data/enums/types';
import React from 'react';
import EnumFilter from 'searchFilters/Shared/EnumFilter';

type Props = {|
  onChange: (?(EnumValue[])) => mixed,
  value: ?(EnumValue[]),
|};

const ProductFilter = (p: Props) => <EnumFilter {...p} paramKey="product" />;

export default ProductFilter;
