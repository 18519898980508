// @flow
import Desktop from 'components/Media/Desktop';
import Mobile from 'components/Media/Mobile';
import {chooseCategories, chooseGeolocation} from 'data/app/actions';
import {selectCategories, selectGeolocation} from 'data/app/selectors';
import {openModal} from 'data/modals/actions';
import {clear, filter} from 'data/search/actions';
import {selectSearchParams} from 'data/search/selectors';
import withConnect from 'hoc/withConnect';
import React from 'react';
import {type HOC, compose} from 'recompose';

import DesktopFilters from '../DesktopFilters';
import MobileFilters from '../MobileFilters';
import {MainFilterWrap} from '../styled';

const MainFilter = ({geolocation, categories, chooseCategories, params, clear, filter}) => {
  return (
    <MainFilterWrap>
      <Mobile>
        <MobileFilters
          geolocation={geolocation}
          categories={categories}
          chooseCategories={chooseCategories}
          // chooseGeolocation={chooseGeolocation} disabled
          params={params}
          filter={filter}
          multipleBrands={false}
        />
      </Mobile>
      <Desktop>
        <DesktopFilters
          geolocation={geolocation}
          categories={categories}
          chooseCategories={chooseCategories}
          // chooseGeolocation={chooseGeolocation} disabled
          params={params}
          filter={filter}
          clear={clear}
          multipleBrands={false}
        />
      </Desktop>
    </MainFilterWrap>
  );
};

const mapStateToProps = state => ({
  params: selectSearchParams(state),
  geolocation: selectGeolocation(state),
  categories: selectCategories(state),
});

const mapDispatchToProps = {
  filter,
  clear,
  chooseGeolocation,
  chooseCategories,
  openModal,
};

const enhancer: HOC<*, {||}> = compose(withConnect(mapStateToProps, mapDispatchToProps));

export default enhancer(MainFilter);
