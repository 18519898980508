// @flow
import type {Query} from 'common/graphql/types';
import {
  affiliateExtensionFragment,
  affiliateFragmentFull,
  affiliateFragmentMinimal,
} from 'data/affiliate/fragments';
import type {AffiliateFull, AffiliateMinimal} from 'data/affiliate/types';
import type {AffiliateExtensionDetails} from 'data/affiliate/types';
import {paginationFragment} from 'data/app/graphql/fragments';
import type {Category, CategoryName} from 'data/app/types';
import type {ID} from 'data/enums/types';
import type {Manufacturer} from 'data/manufacturer/types';
import {productOfferingFragment} from 'data/product/graphql/fragments';
import type {ProductOffering} from 'data/product/types';
import type {EnumFilter, RangeFilter} from 'data/search/graphql/queries/search';
import type {DateString} from 'data/units/date/types';
import gql from 'graphql-tag';
import {path, pathOr} from 'ramda';

export type AffiliateDetailQueryVariables = {
  id: ID,

  start: ?DateString,
  end: ?DateString,

  manufacturerIds: ?(ID[]),
  locationIds: ?(ID[]),
  discountId: ?number,
  categories: ?(CategoryName[]),

  rangeFilters: ?(RangeFilter[]),
  enumFilters: ?(EnumFilter[]),
};

/**
 * Query to support the Affiliate Detail page. Includes affiliate data and
 * filtered list of product offerings.
 */
export const affiliateDetailQuery: Query<
  {
    affiliate: AffiliateFull,
    productOfferings: ProductOffering[],
    manufacturers: Manufacturer[],
  },
  AffiliateDetailQueryVariables
> = {
  gql: gql`
    query affiliateDetailQuery(
      $id: Int!
      $start: String
      $end: String
      $productIds: [Int!]
      $locationIds: [Int!]
      $manufacturerIds: [Int!]
      $categories: [String]
      $discountId: Int
      $rangeFilters: [RangeFilter!]
      $enumFilters: [EnumFilter!]
      $limit: Int
      $offset: Int
    ) {
      affiliate {
        get(id: $id) {
          ...affiliateFragmentFull
        }
      }
      product {
        listProductOfferings(
          filter: {
            start: $start
            end: $end
            productIds: $productIds
            locationIds: $locationIds
            manufacturerIds: $manufacturerIds
            categories: $categories
            discountId: $discountId
            affiliateId: $id
            rangeFilters: $rangeFilters
            enumFilters: $enumFilters
            limit: $limit
            offset: $offset
          }
        ) {
          pageInfo {
            ...paginationFragment
          }
          data {
            ...productOfferingFragment
          }
        }
      }
      manufacturer {
        list(filter: {limit: 999, affiliateIds: [$id]}) {
          data {
            id
            name
          }
        }
      }
    }
    ${paginationFragment}
    ${affiliateFragmentFull}
    ${productOfferingFragment}
  `,
  transform: data => ({
    // $Ramda
    affiliate: path(['affiliate', 'get'], data),
    // $Ramda
    productOfferings: pathOr([], ['product', 'listProductOfferings', 'data'], data),
    // $Ramda
    manufacturers: pathOr([], ['manufacturer', 'list', 'data'], data),
  }),
  pagination: ['product', 'listProductOfferings'],
};

export const nearbyStoresQuery: Query<
  AffiliateMinimal[],
  {categories: ?(CategoryName[]), locationIds: ?(ID[]), discountId: ?number, limit: ?number}
> = {
  gql: gql`
    query nearbyStoresQuery(
      $categories: [String]
      $locationIds: [Int!]
      $discountId: Int
      $limit: Int
    ) {
      affiliate {
        listNearby(
          filter: {
            limit: $limit
            categories: $categories
            locationIds: $locationIds
            discountId: $discountId
          }
        ) {
          data {
            ...affiliateFragmentMinimal
          }
        }
      }
    }
    ${affiliateFragmentMinimal}
  `,
  selector: ['affiliate', 'listNearby', 'data'],
};

export const affiliateCurrentDate: Query<
  {
    affiliateCurrentDate: DateString,
  },
  {|
    affiliateId: ID,
  |}
> = {
  gql: gql`
    query affiliateCurrentDate($affiliateId: Int!) {
      affiliate {
        getCurrentDate(id: $affiliateId)
      }
    }
  `,
  transform: data => ({
    // $Ramda
    affiliateCurrentDate: path(['affiliate', 'getCurrentDate'], data),
  }),
  options: () => ({
    fetchPolicy: 'network-only',
  }),
};

export const affiliateExtensionDetailsQuery: Query<
  {
    affiliateCurrentDate: DateString,
    affiliateExtensionDetails: AffiliateExtensionDetails,
  },
  {|
    affiliateId: ID,
  |}
> = {
  gql: gql`
    query affiliateExtensionDetailsQuery($affiliateId: Int!) {
      affiliate {
        getCurrentDate(id: $affiliateId)
        get(id: $affiliateId) {
          ...affiliateExtensionFragment
        }
      }
    }
    ${affiliateExtensionFragment}
  `,
  transform: data => {
    const affiliateCurrentDate = path(['affiliate', 'getCurrentDate'], data);
    const affiliateExtensionDetails = path(['affiliate', 'get'], data);

    // $Ramda
    return {affiliateCurrentDate, affiliateExtensionDetails};
  },
};

/** Fetches the categories offered by an affiliate */
export const affiliateSupportedCategoriesQuery: Query<{
  supportedCategories: Category[],
}> = {
  gql: gql`
    query affiliateSupportedCategoriesQuery($id: Int!) {
      affiliate {
        get(id: $id) {
          categories {
            categoryId
            name
          }
        }
      }
    }
  `,
  selector: ['affiliate', 'get', 'categories'],
};

export const logisticsSummaryQuery: Query<{
  logisticsSummary: string,
}> = {
  gql: gql`
    query logisticsSummaryQuery($affiliateId: Int!) {
      affiliate {
        get(id: $affiliateId) {
          logisticsSummary
        }
      }
    }
  `,
  selector: ['affiliate', 'get', 'logisticsSummary'],
};
