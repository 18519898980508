// @flow
import Button from 'components/Button';
import Calendar from 'components/Calendar';
import {type Error as ErrorType} from 'components/Calendar/ErrorMessage';
import CollapsibleParagraph from 'components/CollapsibleParagraph';
import {Divider} from 'componentsStyled/Layout/Spacers';
import {Wrap} from 'componentsStyled/Layout/Wrap';
import {logisticsSummaryQuery} from 'data/affiliate/graphql';
import {selectAppConfig} from 'data/app/selectors';
import {addToBasket} from 'data/basket/actions';
import {selectBasketItems} from 'data/basket/selectors';
import type {FulfillmentType} from 'data/bookings/types';
import type {BundleComponent} from 'data/bundle/types';
import type {ProductVariant, ProductVariantAvailableAffiliate} from 'data/product/types';
import {
  clearAvailability,
  pickOriginalReservation,
  pickReservationDates,
} from 'data/reservations/actions';
import urls from 'data/router/urls';
import {formatReadableDateRange} from 'data/units/date/formatters';
import {dateRangeFromValue} from 'data/units/date/helpers';
import type {DateRange, DateRangeValue, DateString} from 'data/units/date/types';
import withConnect from 'hoc/withConnect';
import withQuery from 'hoc/withQuery';
import withRouter from 'hoc/withRouter';
import ModalContent from 'modals/_Content';
import ModalControls from 'modals/_Controls';
import ModalHeader from 'modals/_Header';
import React from 'react';
import {type HOC, compose, withHandlers, withProps, withState, withStateHandlers} from 'recompose';

import {resetDates, setActiveDates} from './helpers';
import Price from './Price';
import {DeliveryInfoSection, StyledDescription, StyledModalBody, Tip} from './styled';

const mapStateToProps = state => ({
  basketItems: selectBasketItems(state),
  appConfig: selectAppConfig(state),
});

const ProductAvailabilityModal = ({
  handleClose,
  value,
  setDay,
  handleClear,
  handleClearEvent,
  handleError,
  handleAddToCart,
  errorMessage,
  productVariants,
  variantAffiliate,
  handlePickDates,
  originalReservation,
  basketItems,
  appConfig,
  fulfillmentType,
  data,
}) => {
  const dates = dateRangeFromValue(value);
  const lastBasketItem = basketItems.length > 0 ? basketItems[basketItems.length - 1] : undefined;
  const productVariantIds = productVariants.map(variant => variant.id);
  const showDeliveryInfo = data && fulfillmentType === 'DELIVERY';
  return (
    <StyledModalBody close={handleClose} wide data-cy={'select-date'}>
      <ModalHeader close={handleClose} title="Dates" clear={handleClearEvent} />
      <ModalContent>
        <Wrap>
          {lastBasketItem && (
            <Tip>
              Tip: Your previously selected date range is from{' '}
              {formatReadableDateRange({
                startDate: lastBasketItem.start,
                endDate: lastBasketItem.end,
              })}
            </Tip>
          )}
          <Calendar
            closeModal={handleClose}
            onChange={setDay}
            onError={handleError}
            errorMessage={errorMessage}
            value={value}
            variantAffiliate={variantAffiliate}
            availabilityVariables={{
              productVariantIds: productVariantIds,
              affiliateId: variantAffiliate.affiliateId,
              fulfillmentType,
            }}
            originalReservation={originalReservation}
            clear={handleClear}
          />
          {showDeliveryInfo && (
            <DeliveryInfoSection>
              <CollapsibleParagraph
                maxHeight="7rem"
                open={true}
                showExpandText={false}
                group="Delivery information"
              >
                <StyledDescription description={data} />
              </CollapsibleParagraph>
              <Divider />
            </DeliveryInfoSection>
          )}
        </Wrap>
      </ModalContent>
      <ModalControls>
        <Price
          variantAffiliate={variantAffiliate}
          dates={dates}
          originalReservation={originalReservation}
        />
        {/*
          If a new reservation is being made and the variant-affiliate
          combination does not have an accessory, direct the customer to the basket page
          with the pending reservation. Else, redirect them to the `Cart` page to select
          an accessory to rent.
        */}
        {!variantAffiliate.hasAccessories && !originalReservation ? (
          <Button
            disabled={!dates}
            onClick={() => handleAddToCart(dates)}
            data-cy={'confirm-selection'}
          >
            Reserve
          </Button>
        ) : (
          <Button disabled={!dates} onClick={handlePickDates} data-cy={'confirm-selection'}>
            {originalReservation ? 'Extend Reservation' : 'Reserve'}
          </Button>
        )}
      </ModalControls>
    </StyledModalBody>
  );
};

type Outter = {|
  close: Function,
  callback?: Function,
  fulfillmentType?: FulfillmentType,
  availabilityInput: ProductVariant | BundleComponent[] | null,
  variantAffiliate: ProductVariantAvailableAffiliate,
  originalReservation?: {
    ...$Exact<DateRange>,
    resId: number,
  },
|};

const mapDispatchToProps = {
  pickOriginalReservation,
  pickReservationDates,
  clearAvailability,
  addToBasket,
};

const enhancer: HOC<*, Outter> = compose(
  withQuery(logisticsSummaryQuery, {
    variables: props => ({affiliateId: props.variantAffiliate.affiliateId}),
    config: {skip: props => props.fulfillmentType !== 'DELIVERY'},
    noEmpty: true,
  }),
  withRouter,
  withConnect(mapStateToProps, mapDispatchToProps),
  withProps(props => {
    if (Array.isArray(props.availabilityInput)) {
      const productVariants = props.availabilityInput.map(
        bundleComponent => bundleComponent.productVariant
      );
      return {productVariants: productVariants};
    } else {
      return {productVariants: [props.availabilityInput]};
    }
  }),
  withStateHandlers(
    {errorMessage: undefined},
    {
      handleError: () => (e: ?ErrorType) => ({errorMessage: e}),
      clearError: () => () => ({errorMessage: undefined}),
    }
  ),
  withState('value', 'setValue', ({originalReservation}) => setActiveDates(originalReservation)),
  withHandlers({
    handlePickDates: props => () => {
      const dates = dateRangeFromValue(props.value);
      if (dates) {
        if (props.originalReservation) {
          props.pickOriginalReservation(props.originalReservation);
        }
        props.pickReservationDates(dates, props.productVariant);
        props.callback();
        props.close();
      }
    },
    setDay: props => (v: DateRangeValue) => {
      props.clearError();
      props.setValue(v);
    },
    handleClear: props => (v?: DateString) => {
      resetDates(props, v);
    },
    handleClearEvent: props => (e: SyntheticEvent<HTMLButtonElement>) => {
      resetDates(props);
    },
    handleClose: props => () => {
      props.close();
      props.clearAvailability();
    },
    handleAddToCart: props => dates => {
      const currentTime = new Date();
      // TODO(Jude): Array indicates bundle components - should make more explicit
      if (Array.isArray(props.availabilityInput)) {
        console.log('********* adding to cart');
        for (const component of props.availabilityInput) {
          const pendingReservation = {
            start: dates.startDate,
            end: dates.endDate,
            productVariant: component.productVariant,
            affiliate: props.variantAffiliate,
            timeCreated: currentTime.toISOString(),
            paymentMethod: 'card',
            accessories: [],
            bundleComponentId: component.id,
            fulfillmentType: props.fulfillmentType,
          };
          props.addToBasket(pendingReservation);
        }
      } else {
        const pendingReservation = {
          start: dates.startDate,
          end: dates.endDate,
          productVariant: props.availabilityInput,
          affiliate: props.variantAffiliate,
          timeCreated: currentTime.toISOString(),
          paymentMethod: 'card',
          accessories: [],
          fulfillmentType: props.fulfillmentType,
        };
        props.addToBasket(pendingReservation);
      }

      const goToShoppingBasket = () => {
        props.close();
        return props.history.push(urls.shoppingBasket);
      };
      return goToShoppingBasket();
    },
  })
);

export default enhancer(ProductAvailabilityModal);
