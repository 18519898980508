// @flow
import {Text} from 'componentsStyled/Typography/Texts';
import styled from 'styled-components';

export const Group = styled.div`
  margin-top: 3rem;
`;

export const Item = styled(Text)`
  display: flex;
  align-items: center;
  cursor: pointer;
`;
export const IconWrap = styled.span`
  svg {
    margin-right: 1rem;
  }
`;
