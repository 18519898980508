// @flow
import Loader from 'components/Loader';
import ProductList from 'components/ProductList';
import {FullWidthContainer} from 'componentsStyled/Layout/Containers';
import type {Category, Location} from 'data/app/types';
import {listProductOfferingsQuery} from 'data/product/graphql/queries';
import withQuery from 'hoc/withQuery';
import withScrollEnd from 'hoc/withScrollEnd';
import * as React from 'react';
import {type HOC, compose} from 'recompose';

const NearbyProducts = ({data, loading, loadingMore, ...rest}) => (
  <FullWidthContainer data-cy={'nearby-products'}>
    <ProductList productOfferings={data.products} loading={loading} />
    <Loader hide={!loadingMore} pb0 />
  </FullWidthContainer>
);

type Outter = {|
  geolocations: ?(Location[]),
  categories: ?(Category[]),
|};

const enhancer: HOC<*, Outter> = compose(
  withQuery(listProductOfferingsQuery, {
    variables: ({activity, geolocations, categories, ...rest}) => ({
      categories: categories ? categories.map(category => category.name) : undefined,
      locationIds: geolocations ? geolocations.map(geo => geo.id) : undefined,
      ...rest,
    }),
  }),
  withScrollEnd(props => props.loadMore())
);

export default enhancer(NearbyProducts);
