// @flow
import type {ProductVariant} from 'data/product/types';

export const getDimensionText = (
  productVariant: ProductVariant,
  highlight: boolean = false,
  center: boolean = false,
  short: boolean = false
) => {
  // NOTE: Due to removal of parameters:
  // https://app.clubhouse.io/awayco/story/1230/differentiate-variants-by-name-not-parameters
  // only the name is used to differentiate between product variants.
  return productVariant.name;
};
