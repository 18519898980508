// @flow
import type {Query} from 'common/graphql/types';
import {affiliateFragmentMinimal, affiliateFulfilmentFragment} from 'data/affiliate/fragments';
import type {AffiliateMinimal} from 'data/affiliate/types';
import {paginationFragment} from 'data/app/graphql/fragments';
import type {CategoryName} from 'data/app/types';
import type {FulfillmentType} from 'data/bookings/types';
import type {ID} from 'data/enums/types';
import type {EnumFilter, RangeFilter} from 'data/search/graphql/queries/search';
import type {DateRange, DateString} from 'data/units/date/types';
import gql from 'graphql-tag';
import {path} from 'ramda';

import type {ProductOffering, ProductVariant, RelatedCategory} from '../types';
import {
  productFragmentWithVariants,
  productOfferingFragment,
  productVariantFragment,
} from './fragments';

/**
 * Fetches the details for a product item.
 */
export const productDetailQuery: Query<
  ProductVariant,
  {|
    productVariantId: ID,
    affiliateId: ID,
  |}
> = {
  gql: gql`
    query productDetailQuery($productVariantId: Int!, $affiliateId: Int!) {
      product {
        getVariant(id: $productVariantId, affiliateId: $affiliateId) {
          ...productVariantFragment
        }
        getProductItem(productVariantId: $productVariantId, affiliateId: $affiliateId) {
          code
        }
      }
    }
    ${productVariantFragment}
  `,
  // need to transform in new data
  transform: data => ({
    ...data.getVariant,
    code: data.getProductItem.code,
  }),
  selector: ['product'],
};

/**
 * Fetches product variants for a product offered by an affiliate.
 */
export const listProductVariantsQuery: Query<
  {
    affiliate: AffiliateMinimal,
    productVariants: ProductVariant[],
  },
  {
    affiliateId: ID,
    productId: ID,
  }
> = {
  gql: gql`
    query listProductVariantsQuery(
      $affiliateId: Int!
      $productId: Int!
      $discountId: Int
      $limit: Int
      $offset: Int
    ) {
      affiliate {
        get(id: $affiliateId) {
          ...affiliateFragmentMinimal
          ...affiliateFulfilmentFragment
        }
      }
      product {
        listProductVariants(
          filter: {
            affiliateId: $affiliateId
            productId: $productId
            discountId: $discountId
            limit: $limit
            offset: $offset
          }
        ) {
          pageInfo {
            ...paginationFragment
          }
          data {
            ...productVariantFragment
          }
        }
      }
    }
    ${paginationFragment}
    ${productVariantFragment}
    ${affiliateFragmentMinimal}
    ${affiliateFulfilmentFragment}
  `,
  transform: data => ({
    // $Ramda
    affiliate: path(['affiliate', 'get'], data),
    // $Ramda
    productVariants: path(['product', 'listProductVariants', 'data'], data),
  }),
  pagination: ['product', 'listProductVariants'],
};

export const listPopularProductsByReservationCount: Query<
  {
    //TODO(HARRY) write a type for this that captures the variants as children of products
    products: any,
  },
  {
    limit: number,
    offset: number,
    category: string,
    availableOnly: boolean,
  }
> = {
  gql: gql`
    query listPopularProductsByReservationCountQuery(
      $limit: Int
      $offset: Int
      $category: String
      $availableOnly: Boolean
    ) {
      product {
        listPopularProductsByReservationCount(
          filter: {
            limit: $limit
            offset: $offset
            category: $category
            availableOnly: $availableOnly
          }
        ) {
          pageInfo {
            ...paginationFragment
          }
          data {
            ...productFragment
          }
        }
      }
    }
    ${paginationFragment}
    ${productFragmentWithVariants}
  `,
  transform: data => ({
    products: path(['product', 'listPopularProductsByReservationCount', 'data'], data),
  }),
  pagination: ['product', 'listPopularProductsByReservationCount'],
};

export const listPopularRelatedProductsByReservationCount: Query<
  {
    relatedCategories: RelatedCategory[],
  },
  {
    limit: number,
    category: string,
    productId?: ID,
  }
> = {
  gql: gql`
    query listPopularRelatedProductsByReservationCountQuery(
      $limit: Int
      $category: String
      $productId: Int
    ) {
      product {
        listPopularRelatedProductsByReservationCount(
          filter: {limit: $limit, category: $category, productId: $productId}
        ) {
          relatedCategories {
            name
            productOfferings {
              productId
              name
              manufacturer {
                id
                name
              }
              categories {
                categoryId
                name
              }
              images {
                alt
                order
                sizes {
                  height
                  width
                  url
                }
              }
              affiliateId
              affiliateName
              pricingDetails {
                prices
              }
              currency
            }
          }
        }
      }
    }
  `,
  selector: ['product', 'listPopularRelatedProductsByReservationCount'],
};

/**
 * Searches for products based on a filter. Returns just the id and name.
 */
export const listProductsMinimalQuery: Query<
  {
    id: ID,
    name: string,
  }[]
> = {
  gql: gql`
    query productListQueryMinimal($filter: ProductFilter) {
      product {
        list(filter: $filter) {
          pageInfo {
            ...paginationFragment
          }
          data {
            id
            name
          }
        }
      }
    }
    ${paginationFragment}
  `,
  selector: ['product', 'list', 'data'],
  pagination: ['product', 'list'],
};

/**
 * Searches for product offerings (NOTE: not variant offerings) based on a
 * filter as well as the affiliates that match the search filter.
 */
export const listProductOfferingsQuery: Query<
  {
    affiliates: Array<AffiliateMinimal>,
    products: Array<ProductOffering>,
  },
  {
    start?: DateString,
    end?: DateString,
    productIds?: Array<ID>,
    manufacturerIds?: Array<ID>,
    locationIds?: Array<ID>,
    discountId?: number,
    categories?: Array<CategoryName>,
    rangeFilters?: Array<RangeFilter>,
    enumFilters?: Array<EnumFilter>,
  }
> = {
  gql: gql`
    query listProductOfferingsQuery(
      $start: String
      $end: String
      $productIds: [Int!]
      $locationIds: [Int!]
      $manufacturerIds: [Int!]
      $categories: [String]
      $discountId: Int
      $rangeFilters: [RangeFilter!]
      $enumFilters: [EnumFilter!]
      $limit: Int
      $offset: Int
    ) {
      affiliate {
        list(
          filter: {
            categories: $categories
            locationIds: $locationIds
            manufacturerIds: $manufacturerIds
            allowed: true
            discountId: $discountId
            limit: 30
          }
        ) {
          data {
            ...affiliateFragmentMinimal
          }
        }
      }
      product {
        listProductOfferings(
          filter: {
            start: $start
            end: $end
            productIds: $productIds
            locationIds: $locationIds
            manufacturerIds: $manufacturerIds
            categories: $categories
            discountId: $discountId
            rangeFilters: $rangeFilters
            enumFilters: $enumFilters
            limit: $limit
            offset: $offset
          }
        ) {
          pageInfo {
            ...paginationFragment
          }
          data {
            ...productOfferingFragment
          }
        }
      }
    }
    ${paginationFragment}
    ${productOfferingFragment}
    ${affiliateFragmentMinimal}
  `,

  transform: data => ({
    // $Ramda
    affiliates: path(['affiliate', 'list', 'data'], data),
    // $Ramda
    products: path(['product', 'listProductOfferings', 'data'], data),
  }),

  // Paginate on products (not affiliates)
  pagination: ['product', 'listProductOfferings'],
};

/**
 * Lists product item offerings based on the user's location.
 */
export const nearbyProductsQuery: Query<
  ProductVariant[],
  {|categories: ?(CategoryName[]), locationIds: ?(ID[])|}
> = {
  gql: gql`
    query nearbyProductsQuery(
      $categories: [String]
      $locationIds: [Int!]
      $discountId: Int
      $offset: Int
    ) {
      product {
        listRecommended(
          filter: {
            limit: 8
            categories: $categories
            locationIds: $locationIds
            discountId: $discountId
            offset: $offset
          }
        ) {
          pageInfo {
            ...paginationFragment
          }
          data {
            ...productVariantFragment
          }
        }
      }
    }
    ${paginationFragment}
    ${productVariantFragment}
  `,
  selector: ['product', 'listRecommended', 'data'],
  pagination: ['product', 'listRecommended'],
};

/**
 * Checks for the intersection in availability for a list of product variants offered by an affiliate.
 */
export const productAvailabilityQuery: Query<
  {
    availability: DateRange[],
    closedDays: DateString[],
    affiliateCurrentDate: DateString,
  },
  {|
    variantsAvailabilityFilter: {|
      productVariantIds: ID[],
      affiliateId: ID,
      start: DateString,
      end: DateString,
      trimClosedFromStart: boolean,
      availabilityForExtension: ?ID,
      fulfillmentType?: FulfillmentType,
    |},
    closedDaysFilter: {|
      affiliateId: ID,
      start: DateString,
      end: DateString,
    |},
    affiliateId: ID,
  |}
> = {
  gql: gql`
    query productAvailabilityQuery(
      $variantsAvailabilityFilter: VariantsAvailabilityFilter
      $closedDaysFilter: AffiliateClosedDatesFilter
      $affiliateId: Int!
    ) {
      product {
        getAvailableDatesForVariants(filter: $variantsAvailabilityFilter) {
          startDate: start
          endDate: end
        }
      }
      affiliate {
        getClosedDates(filter: $closedDaysFilter)
        getCurrentDate(id: $affiliateId)
      }
    }
  `,
  transform: data => ({
    // $Ramda
    availability: path(['product', 'getAvailableDatesForVariants'], data),
    // $Ramda
    closedDays: path(['affiliate', 'getClosedDates'], data),
    // $Ramda
    affiliateCurrentDate: path(['affiliate', 'getCurrentDate'], data),
  }),
  options: () => ({
    fetchPolicy: 'network-only',
  }),
};
