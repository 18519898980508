// @flow
import type {SearchQueryVariables} from 'data/search/graphql/queries/search';
import {formatReadableFullYear} from 'data/units/date/formatters';

import {tryTrack} from '../helpers';

const searchTraits = (s: ?SearchQueryVariables, email: string) => {
  if (!s) return {};

  return {
    start: s.start && formatReadableFullYear(s.start),
    end: s.end && formatReadableFullYear(s.end),
    name: s.name,
    categories: s.categories,
    manufacturerIds: s.manufacturerIds,
    locationIds: s.locationIds,
    enumFilters: s.enumFilters,
    rangeFilters: s.rangeFilters,
    email: email,
  };
};

export const trackSearch = (s: ?SearchQueryVariables, email: string) =>
  tryTrack('Product Searched', () => searchTraits(s, email));
