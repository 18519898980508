// @flow
import type {EnumValue} from 'data/enums/types';

export const formatEnumArray = (title: string) => (v: EnumValue[]) => {
  if (!v || v.length === 0) return title;

  if (v.length === 1) return v[0].name;

  return `${title} • ${v.length}`;
};
