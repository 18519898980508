// @flow
import {center} from 'componentsStyled/Shared';
import styled, {css} from 'styled-components';

export const CheckboxWrap = styled.div`
  width: 2.4rem;
  height: 2.4rem;
  border: 0.1rem solid ${props => props.theme.color.lightGray};
  border-radius: ${props => props.theme.borderRadiuses.input};
  position: relative;
  flex-shrink: 0;
  cursor: pointer;
  background: ${props => props.theme.color.white};

  ${p =>
    p.big &&
    css`
      height: 4.8rem;
      width: 4.8rem;
    `}

  ${p =>
    p.checked &&
    css`
      background: ${props => props.theme.color.primaryAccent};
      border-color: ${props => props.theme.color.primaryAccent};
    `}

  svg {
    ${center};
  }
`;
