// @flow
import {chooseCategories, chooseGeolocation} from 'data/app/actions';
import {formatCategoryFilter, formatLocation} from 'data/app/formatters';
import type {Category, Location} from 'data/app/types';
import {openModal} from 'data/modals/actions';
import {filter} from 'data/search/actions';
import type {SearchParams} from 'data/search/types';
import withConnect from 'hoc/withConnect';
import CustomComponent from 'modals/CustomComponent';
import React from 'react';
import {type HOC, compose, withHandlers} from 'recompose';
import CategoryFilter from 'searchFilters/FilterComponents/CategoryFilter';
import LocationFilter from 'searchFilters/FilterComponents/LocationFilter';
import FilterInput from 'searchFilters/Shared/FilterInput';

const AllActivityFilters = ({
  geolocation,
  categories,
  handlePickLocation,
  handlePickCategory,
  chooseGeolocation,
  params,
}) => (
  <React.Fragment>
    {handlePickCategory && (
      <FilterInput
        placeholder="Category"
        value={categories}
        formatter={formatCategoryFilter('Category')}
        onClick={handlePickCategory}
      />
    )}
    {chooseGeolocation && (
      <FilterInput
        placeholder="Location"
        value={geolocation}
        formatter={formatLocation}
        onClick={handlePickLocation}
      />
    )}
  </React.Fragment>
);

type Outter = {|
  geolocation: ?(Location[]),
  categories: ?(Category[]),
  chooseGeolocation?: typeof chooseGeolocation,
  chooseCategories: typeof chooseCategories,
  filter: typeof filter,
  params: SearchParams,
|};

const enhancer: HOC<*, Outter> = compose(
  withConnect(() => ({}), {openModal}),
  withHandlers({
    handlePickLocation: props => () =>
      props.chooseGeolocation &&
      props.openModal(CustomComponent, {
        title: 'Location',
        component: LocationFilter,
        onChange: props.chooseGeolocation,
      }),
    handlePickCategory: props => () =>
      props.chooseCategories &&
      props.openModal(CustomComponent, {
        title: 'Type',
        component: CategoryFilter,
        onChange: props.chooseCategories,
        value: props.categories,
      }),
  })
);

export default enhancer(AllActivityFilters);
