// @flow
import BundleTile from 'components/BundleTile';
import type {BundleOffering, Offering} from 'data/bundle/types';
import type {ProductOffering} from 'data/product/types';
import withEmpty from 'hoc/withEmpty';
import withLoader from 'hoc/withLoader';
import React from 'react';
import {type HOC, compose} from 'recompose';

import ProductTile from '../ProductList/ProductTile';
import {ProductListWrap} from './styled';

/**
 * - bundles and products inline togethor
 * - pricing display for bundles (aggregate of components)
 * - displaying of bundles different affiliates (product filter for bundles)
 */
const OfferingList = ({offerings, maxTilesPerRow}) => {
  return (
    <ProductListWrap>
      {/*HACK: Have to cast to 'any' to allow us to cast the properites correctly */}
      {offerings.map((offering: any) => {
        if (offering.__typename === 'ProductOffering') {
          const productOffering: ProductOffering = {
            ...offering,
          };
          return (
            <ProductTile
              key={productOffering.productId + '-' + productOffering.affiliateId}
              productOffering={productOffering}
              maxTilesPerRow={maxTilesPerRow}
            />
          );
        } else if (offering.__typename === 'BundleOffering') {
          const bundleOffering: BundleOffering = {
            ...offering,
          };
          return (
            <BundleTile
              key={bundleOffering.bundleId + '-' + bundleOffering.affiliateId}
              bundleOffering={bundleOffering}
              maxTilesPerRow={maxTilesPerRow}
            />
          );
        }
        return null;
      })}
    </ProductListWrap>
  );
};

type Outter = {|
  loading?: boolean,
  offerings: Array<Offering>,
  maxTilesPerRow?: number,
|};

const enhancer: HOC<*, Outter> = compose(
  withLoader(props => !!props.loading),
  withEmpty(props => !props.offerings || !props.offerings.length)
);

export default enhancer(OfferingList);
